import { z } from 'zod';
import { DeployParamKind, deployParamSchema } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { varNameSchema, varValSchema } from '../../var.zod';
import { varpConfigBase } from './var-provider.zod';

export const localVarCfgSchema = z.object({
    name: varNameSchema,
    type: z.enum([ 'str', 'num', 'null' ]).default('str'),
    initialVal: varValSchema,
});

const localVarCfgDeployParamSchema = deployParamSchema(localVarCfgSchema.extend({
    type: z.enum([ 'str', 'num' ]), // omit `null` type, because then the only allowed deployment param value would be `null`
    deployParamKind: z.literal(DeployParamKind.localVarValue),
}));


export const localProviderConfigSchema = varpConfigBase.extend({
    type: z.literal('local'),
    attrs: z.object({
        vars: z.array(z.union([
            localVarCfgDeployParamSchema,
            localVarCfgSchema.strict(), // strict to prevent using this schema to parse the deploy param version
        ])),
    }),
});

