import z from 'zod';
import { baseMacroCfgSchema } from './base-macro.zod';
import { materialLookupAttrsSchema } from '../cell-types/material-lookup.zod';

export const materialLookupCfgSchema = baseMacroCfgSchema.extend({
    type: z.literal('materialLookup'),
    attrs: z.object({
        materialLookupAttrs: materialLookupAttrsSchema,
    }),
});
