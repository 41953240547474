import { mutationTree } from 'typed-vuex';

export type FeatureFlags = Record<'materialMarriage', string | undefined>;

const routerState = {
    k8sChartNamespace: '',
    featureFlags: {} as FeatureFlags,
};

const mutations = mutationTree(routerState, {
    setChartNamespace (state, namespace: string) {
        state.k8sChartNamespace = namespace;
    },
    setFeatureFlags (state, flags: FeatureFlags) {
        state.featureFlags = flags;
    },
});

export default {
    state: routerState,
    mutations,
};
