/* eslint sort-keys: "error" */
/* eslint-disable max-lines */
import { messages } from 'src/i18n/i18n';
import { v4 as uuidv4 } from 'uuid';
import { Component } from 'vue';
import macroTypeMap from '@redviking/argonaut-core-ui/src/applet/design/macros/macroTypeMap';
import { accessor } from 'src/store/store';
import { validateComparisons } from '@redviking/argonaut-core-ui/src/mes/lib/validate-comparison';
import { gatherComparisonInputs } from '@redviking/argonaut-util/src/mes/gather-comparison-inputs';
import { AppletDesignMenuItem } from '@redviking/argonaut-core-ui/src/applet/design/util/AppletDesignMenuItem';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
type PartialAttrs<T> = T extends object ? { [P in keyof T]?: Partial<T[P]> } : T;

export interface CellTypeMapItem<C extends Latest.Screen.Cells.AppletCell = Latest.Screen.Cells.AppletCell> extends AppletDesignMenuItem<C['type']> {
    /**
     * Creates a default cell of this type. Can receive a partial cell configuration for overriding defaults
     */
    defaultCell?: (cfg?: PartialAttrs<C>) => C;
    validator: null | ((cellCfg: Latest.Screen.Cells.AppletCell, vars: string[]) => void);
    /**
     * The component for configuration, when the item is selected
     */
    tab?: () => Promise<Component>;
    /**
     * the component for the treeview item
     * if not specified, the generic tree item will be used
     */
    tree?: () => Promise<Component>;
    /**
     * allows cell types to customize their delete behavior.
     * this function will run just before the cell is actually deleted.
     * Type is a generic cell type in order to decrease the complexity of the function signature. Cell type checking should be handled in the delete function.
     */
    delete?: (cellCfg: Latest.Screen.Cells.AppletCell) => void;
    icon?: string | ((cellCfg: Latest.Screen.Cells.AppletCell) => string);
    treeText?: (cellCfg: Latest.Screen.Cells.AppletCell) => string;
}

export type CellTypeMap = {
    [cellTypeKey in Latest.Screen.Cells.AppletCellType]: CellTypeMapItem<Latest.Screen.Cells.AppletCell & { type: cellTypeKey }>;
};

/**
 * defines how cells are configured
 */
export const cellTypeMap: CellTypeMap = {
    attrLookup: {
        defaultCell: cfg => {
            const cell: Latest.Screen.Cells.AttrLookup.Cell = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    attrSchemaId: cfg?.attrs?.attrSchemaId || '',
                    cols: cfg?.attrs?.cols || [],
                    conds: cfg?.attrs?.conds || [
                        {
                            attrSchemaPropId: '',
                            constOperand: '',
                            not: false,
                            type: 'propMatchStr',
                        },
                    ],
                    materialIdVar: cfg?.attrs?.materialIdVar || '',
                    parentCellId: cfg?.attrs?.parentCellId || '',
                    showName: true,
                },
                id: uuidv4(),
                type: 'attrLookup',
            };
            return cell;
        },
        description: messages.mes.cells.attrLookup.description,
        icon: 'mdi-table-search',
        tab: () => import('src/applet/design/cells/attr-lookup/AttrLookup.tab.cell.vue').then(m => m.default),
        text: messages.mes.cells.attrLookup.title,
        type: 'attrLookup',
        validator: (cell, vars) => {
            if (cell.type !== 'attrLookup') {
                return;
            }
            if (!cell.attrs.attrSchemaId) {
                throw new Error(messages.mes.cells.attrLookup.error.attrSchema);
            }
            if (cell.attrs.cols.length === 0) {
                throw new Error(messages.mes.cells.attrLookup.error.cols);
            }
            if (!cell.attrs.materialIdVar) {
                // TODO what if this variable gets deleted, but this is still set?
                throw new Error(messages.mes.cells.attrLookup.error.materialId);
            }
            for (const cond of cell.attrs.conds) {
                if ('attrSchemaPropId' in cond) {
                    if (!cond.attrSchemaPropId) {
                        throw new Error(messages.mes.cells.attrLookup.error.propId);
                    }
                }
                if (cond.source === 'var') {
                    if (!cond.varOperand) {
                        throw new Error(messages.mes.cells.attrLookup.error.varNotSet);
                    }
                    if (!vars.includes(cond.varOperand)) {
                        throw new Error(messages.mes.cells.attrLookup.error.missingVar(cond.varOperand || ''));
                    }
                }
            }
        },
    },
    btn: {
        defaultCell: cfg => {
            const cell: Latest.Screen.Cells.Btn.Cell = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    color: cfg?.attrs?.color || '#21b8bb',
                    enabled: cfg?.attrs?.enabled || 'true',
                    label: cfg?.attrs?.label || 'Button',
                    parentCellId: cfg?.attrs?.parentCellId || '',
                    size: cfg?.attrs?.size || 'medium',
                    targetVar: cfg?.attrs?.targetVar || '',
                    varBehavior: cfg?.attrs?.varBehavior || {
                        type: 'timestamp',
                    },
                    variant: cfg?.attrs?.variant || null,
                },
                id: uuidv4(),
                type: 'btn',
            };
            return cell;
        },
        delete (cell) {
            if (cell.type === 'btn' && cell.attrs.linkedScriptId) {
                accessor.appletDesign.removeScript({
                    scriptId: cell.attrs.linkedScriptId,
                });
            }
        },
        description: messages.mes.cells.btn.description,
        icon: 'mdi-gesture-tap-button',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/Btn.tab.cell.vue').then(m => m.default),
        text: messages.mes.cells.btn.title,
        treeText: cell => {
            if (cell.type !== 'btn') {
                return '';
            }
            return cell.attrs.targetVar || cell.attrs.label;
        },
        type: 'btn',
        validator: (cell) => {
            if (cell.type !== 'btn') {
                return;
            }
            if (!cell.attrs.targetVar?.length && [ 'constant', 'script', 'timestamp' ].includes(cell.attrs.varBehavior?.type || '')) {
                throw new Error('Target Variable required for Button');
            }
            if (cell.attrs.targetVar && !accessor.appletDesign.localVariables.includes(cell.attrs.targetVar) && [ 'constant', 'script', 'timestamp' ].includes(cell.attrs.varBehavior?.type || '')) {
                throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({ cellLabel: cell.name, cellType: cell.type, varName: cell.attrs.targetVar }));
            }
            if (cell.attrs.varBehavior?.type === 'navToScreen' && !cell.attrs.targetScreenId) {
                throw new Error('Target Screen required for Button with navigation behavior');
            }
        },
    },
    circularProgress: {
        defaultCell: cfg => {
            const cell: Latest.Screen.Cells.CircularProgressCell = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    dialColor: cfg?.attrs?.dialColor || '#4CAF50FF',
                    innerText: cfg?.attrs?.innerText || '',
                    maxValue: cfg?.attrs?.maxValue || 100,
                    minValue: cfg?.attrs?.minValue || 0,
                    parentCellId: cfg?.attrs?.parentCellId || '',
                    sourceVar: cfg?.attrs?.sourceVar || '',
                },
                id: uuidv4(),
                type: 'circularProgress',
            };
            return cell;
        },
        description: messages.mes.cells.circularProgress.description,
        icon: 'mdi-refresh',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/CircularProgress.tab.cell.vue').then(m => m.default),
        text: 'Circular Progress',
        type: 'circularProgress',
        validator: (cfg, vars) => {
            if (cfg.type !== 'circularProgress') {
                return;
            }
            if (!cfg.attrs.sourceVar) {
                throw new Error('Circular Progress variable is required');
            }
            if (cfg.attrs.minValue > cfg.attrs.maxValue) {
                throw new Error('Min value must be less than max value');
            }
            if (cfg.attrs.flashingCondition) {
                const clearConditionInputs = gatherComparisonInputs(cfg.attrs.flashingCondition.comparisons);
                for (const input of clearConditionInputs) {
                    if (!vars.includes(input)) {
                        throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({ cellLabel: cfg.name, cellType: cfg.type, varName: input }));
                    }
                }
                validateComparisons(cfg.attrs.flashingCondition.comparisons);
            }
        },
    },
    datetime: {
        defaultCell: cfg => {
            const cell: Latest.Screen.Cells.DateTimeCell = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    format: cfg?.attrs?.format || 'datetime',
                    parentCellId: cfg?.attrs?.parentCellId || '',
                },
                id: uuidv4(),
                type: 'datetime',
            };
            return cell;
        },
        description: messages.mes.cells.datetime.description,
        icon: 'mdi-timetable',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/DateTime.tab.cell.vue').then(m => m.default),
        text: messages.mes.cells.datetime.title,
        type: 'datetime',
        validator: null,
    },
    dialog: {
        disableAdd: true,
        text: 'Dialog',
        type: 'dialog',
        validator: null,
    },
    input: {
        defaultCell: cfg => {
            const cell: Latest.Screen.Cells.InputCell = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    btnText: cfg?.attrs?.btnText || 'Submit',
                    captureFocus: cfg?.attrs?.captureFocus || false,
                    enabled: cfg?.attrs?.enabled || 1,
                    errorMsg: cfg?.attrs?.errorMsg || '',
                    label: cfg?.attrs?.label || 'Input',
                    parentCellId: cfg?.attrs?.parentCellId || '',
                    trim: cfg?.attrs?.trim || true,
                    writeVar: cfg?.attrs?.writeVar || '',
                },
                id: uuidv4(),
                type: 'input',
            };
            return cell;
        },
        description: messages.mes.cells.input.description,
        icon: 'mdi-form-textbox',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/Input.tab.cell.vue').then(m => m.default),
        text: messages.mes.cells.input.title,
        treeText: cell => {
            if (cell.type !== 'input') {
                return '';
            }
            return cell.attrs.writeVar || cell.attrs.label;
        },
        type: 'input',
        validator: (cell, vars) => {
            if (cell.type !== 'input') {
                return;
            }
            if (!cell.attrs.writeVar) {
                throw new Error('Target Variable required for Input');
            }
            if (!accessor.appletDesign.localVariables.includes(cell.attrs.writeVar)) {
                throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({ cellLabel: cell.name, cellType: cell.type, varName: cell.attrs.writeVar }));
            }
            if (cell.attrs.clearCondition) {
                const clearConditionInputs = gatherComparisonInputs(cell.attrs.clearCondition.comparisons);
                for (const input of clearConditionInputs) {
                    if (!vars.includes(input)) {
                        throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({ cellLabel: cell.name, cellType: cell.type, varName: input }));
                    }
                }
                validateComparisons(cell.attrs.clearCondition.comparisons);
            }
        },
    },
    macroTarget: {
        delete (cfg) {
            if (cfg.type !== 'macroTarget') {
                return;
            }
            accessor.appletDesign.removeMacroCfg({
                macroId: cfg.attrs.macroId,
            });
        },
        disableAdd: true,
        icon: cfg => {
            // TODO is this function used?
            if (cfg.type !== 'macroTarget') {
                return '';
            }
            return macroTypeMap[cfg.attrs.macroType].icon;
        },
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/macroTarget/MacroTarget.tab.cell.vue').then(m => m.default),
        text: '',
        tree: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/macroTarget/MacroTarget.tree.cell.vue').then(m => m.default),
        type: 'macroTarget',
        validator: null,
    },
    materialLookup: {
        description: messages.mes.cells.materialLookup.description,
        disableAdd: true,
        tab: () => import('src/applet/design/macros/material-lookup/MaterialLookup.tab.macro.vue').then(m => m.default),
        text: messages.mes.cells.materialLookup.title,
        type: 'materialLookup',
        validator: null,
    },
    media: {
        defaultCell: cfg => {
            const cell: Latest.Screen.Cells.Media.Cell = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    items: cfg?.attrs?.items || [],
                    parentCellId: cfg?.attrs?.parentCellId || '',
                },
                id: uuidv4(),
                type: 'media',
            };
            return cell;
        },
        description: messages.mes.cells.media.description,
        icon: 'mdi-image',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/Media.tab.cell.vue').then(m => m.default),
        text: messages.mes.cells.media.title,
        type: 'media',
        validator: (cell, vars) => {
            if (cell.type !== 'media') {
                return;
            }
            for (const item of cell.attrs.items) {
                if (item.variable && !vars.includes(item.variable)) {
                    throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({ cellLabel: cell.name, cellType: cell.type, varName: item.variable }));
                }
            }
        },
    },
    page: {
        delete: cellCfg => {
            if (cellCfg.type !== 'page') {
                return;
            }
            const pagedCell = accessor.appletDesign.findCellInCfg<Latest.Screen.Cells.MacroTargetCell>(cell => cell.id === cellCfg.attrs.parentCellId)?.cellCfg;
            if (!pagedCell) {
                return;
            }
            const macro = accessor.appletDesign.idMap.macros[pagedCell.attrs.macroId]?.macro;
            if (!macro || macro.type !== 'paged') {
                return;
            }
            macro.attrs.pagedAttrs.pages = macro.attrs.pagedAttrs.pages.filter(p => p.id !== cellCfg.attrs.pageMacroId);
            accessor.appletDesign.setMacroCfg({
                macroCfg: macro,
            });
        },
        disableAdd: true,
        icon: 'mdi-card-bulleted',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/paged/Page.tab.cell.vue').then(m => m.default),
        text: messages.mes.cells.page.title,
        treeText: (cell) => {
            if (cell.type !== 'page') {
                return '';
            }
            const macroTargetCell = accessor.appletDesign.findCellInCfg<Latest.Screen.Cells.MacroTargetCell>(c => c.id === cell.attrs.parentCellId)?.cellCfg;
            const pagedMacro = macroTargetCell
                ? accessor.appletDesign.idMap.macros[macroTargetCell.attrs.macroId]?.macro
                : null;
            const pageNameCfg = pagedMacro?.type === 'paged'
                ? pagedMacro.attrs.pagedAttrs.pages.find(p => p.id === cell.attrs.pageMacroId)?.pageName
                : null;
            const pageName = pageNameCfg?.type === 'var'
                ? pageNameCfg.var
                : pageNameCfg?.val;
            return pageName || cell.name || 'Page';
        },
        type: 'page',
        validator: null,
    },
    paged: {
        description: messages.mes.cells.paged.description,
        disableAdd: true,
        icon: 'mdi-card-bulleted-settings',
        text: messages.mes.cells.paged.title,
        type: 'paged',
        validator: null,
    },
    process: {
        disableAdd: true,
        icon: 'mdi-order-bool-ascending-variant',
        text: 'Process',
        type: 'process',
        validator: null,
    },
    processMedia: {
        description: '',
        disableAdd: true,
        icon: 'mdi-order-bool-ascending-variant',
        text: '',
        type: 'processMedia',
        validator: null,
    },
    text: {
        defaultCell: cfg => {
            const cell: Latest.Screen.Cells.TextCell = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    content: cfg?.attrs?.content || 'sample text',
                    parentCellId: cfg?.attrs?.parentCellId || '',
                    textColor: cfg?.attrs?.textColor || 'black',
                },
                id: uuidv4(),
                type: 'text',
            };
            return cell;
        },
        description: messages.mes.cells.text.description,
        icon: 'mdi-alphabetical-variant',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/Text.tab.cell.vue').then(m => m.default),
        text: messages.mes.cells.text.title,
        treeText: cell => {
            if (cell.type !== 'text') {
                return '';
            }
            return cell.modifiers?.content || cell.attrs.content;
        },
        type: 'text',
        validator: null,
    },
    timer: {
        defaultCell: cfg => {
            const cell: Latest.Screen.Cells.TimerCell = {
                ...cfg,
                attrs: {
                    ...cfg?.attrs,
                    dialColor: cfg?.attrs?.dialColor || '#4CAF50FF',
                    flashingThreshold: cfg?.attrs?.flashingThreshold || 10,
                    format: cfg?.attrs?.format || 'hh:mm:ss',
                    maxValue: cfg?.attrs?.maxValue || 180,
                    parentCellId: cfg?.attrs?.parentCellId || '',
                    sourceVar: cfg?.attrs?.sourceVar || '',
                },
                id: uuidv4(),
                type: 'timer',
            };
            return cell;
        },
        description: messages.mes.cells.timer.description,
        icon: 'mdi-timer',
        tab: () => import('@redviking/argonaut-core-ui/src/applet/design/cells/Timer.tab.cell.vue').then(m => m.default),
        text: messages.mes.cells.timer.title,
        type: 'timer',
        validator: (cell, vars) => {
            if (cell.type !== 'timer') {
                return;
            }
            if (!cell.attrs.sourceVar) {
                throw new Error('Timer variable is required');
            }
            if (!vars.includes(cell.attrs.sourceVar)) {
                throw new Error(messages.mes.errors.appConfig.nonexistentVarCell({ cellLabel: cell.name, cellType: cell.type, varName: cell.attrs.sourceVar }));
            }
            return null;
        },
    },
};

export const cellTypeMapItems = Object.keys(cellTypeMap).map(k => cellTypeMap[k as Latest.Screen.Cells.AppletCellType]) as CellTypeMapItem[];

export default cellTypeMap;

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept('@redviking/argonaut-core-ui/src/applet/design/macros/macroTypeMap');
    import.meta.webpackHot.accept('@redviking/argonaut-core-ui/src/i18n/i18n');
}
