
import {
    PropType,
    computed,
    defineComponent,
} from 'vue';
import { ChangeLog } from './entity-route-util';

export const EntityDetailChangesView = defineComponent({
    name: 'EntityDetailChangesView',
    props: {
        entityChangesDetail: {
            required: true,
            type: Object as PropType<ChangeLog.EntityChangesDetail>,
        },
    },
    setup (props) {
        const categories = computed(() => {
            const changes = props.entityChangesDetail.getChanges();
            return changes;
        });
        return {
            categories,
        };
    },
});
export default EntityDetailChangesView;

