import z from 'zod';
import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

const baseAttrLookupCondition = z.object({
    type: z.string(),
    not: z.boolean(),
    source: z.enum([ 'var', 'const' ]).default('const').optional(),
    varOperand: z.string().default('').optional(),
});

const propMatchNumSchema = baseAttrLookupCondition.extend({
    type: z.literal('propMatchNum'),
    comparison: z.enum([ 'eq', 'lt', 'gt' ]),
    /** ID of the attr prop to be used as an operand */
    attrSchemaPropId: z.string(),
    constOperand: z.number(),
});

const propMatchStrSchema = baseAttrLookupCondition.extend({
    type: z.literal('propMatchStr'),
    /** ID of the attr prop to be used as an operand */
    attrSchemaPropId: z.string(),
    constOperand: z.string(),
});

const nameMatchSchema = baseAttrLookupCondition.extend({
    type: z.literal('nameMatch'),
    constOperand: z.string(),
});

const attrLookupConditionSchema = z.discriminatedUnion('type', [
    propMatchNumSchema,
    propMatchStrSchema,
    nameMatchSchema,
]);

export const attrLookupCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('attrLookup'),
    attrs: cellCfgAttrsBaseSchema.extend({
        attrSchemaId: z.string(),
        showName: z.boolean().default(false),
        materialIdVar: z.string(),
        cols: z.array(z.string()),
        conds: attrLookupConditionSchema.array(),
    }),
});
