import { EntityType, EntityTypeMap } from '@redviking/argonaut-core-ui/types/entity';
import { accessor } from '../../store';
import type { SaveResult } from 'types/routes';
import { Component, NavigationGuardNext, RawLocation, RedirectOption, Route, RouteConfig, VueRouter } from 'vue-router/types/router';
import type RouteEntityDetail from './RouteEntityDetail.view.vue';
import { EntityDetailMode } from './mode';
import { TransitionType } from '../RouterViewSlider';
import type { Component as VueComponent } from 'vue';

export type EntityTab = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attrs?: Record<string, any>,
    label: string,
    icon: string,
};

type PageBannerTitle = string | ((route: Route) => string);

export interface EntityTabsRoute extends Extract<RouteConfig, { component?: unknown }> {
    redirect: RedirectOption,
    props?: Record<string, unknown>;
    meta?: {
        pageBannerTitle?: PageBannerTitle;
        pageBannerSubtitle?: PageBannerTitle;
        pageBannerIcon?: PageBannerTitle;
        /** to be inserted for the route name to navigate to when the EDC back button is pressed*/
        returnRoute: RawLocation | ((from: Route) => RawLocation);
        /**
         * will show a dialog if the user tries to leave the page with unsaved edits.
         * @default true
         */
        warnUnsaved?: boolean;
    },
    children: {
        path: string,
        name: string,
        component?: Component,
        meta: {
            tab: {
                label: string,
                icon: string,
            },
            pageBannerSubtitle?: PageBannerTitle;
            navigationGuard?: (route: Route) => boolean;
        },
        redirect?: RouteConfig['redirect'],
        children?: RouteConfig[];
    }[];
}

interface EntityDetailRouteConfigBase extends Extract<RouteConfig, { component?: unknown }> {
    path: string;
    name: string;
    component: Component;
    redirect: RedirectOption;
    props?: Record<string, unknown>;
    meta: {
        pageBannerTitle: PageBannerTitle; // an entity maintenance page must have a title
        pageBannerSubtitle?: PageBannerTitle;

        canEdit: boolean | ((router: VueRouter) => boolean);
        /** If used with a RouterViewSlider, this is the transition that will be used to deliver this page */
        transition?: TransitionType;
    } & Partial<EntityTabsRoute['meta']>;
    children: RouteConfig[];
}

export type HasChanges = (route: Route) => boolean;

export type EntityModifyParameters<E extends EntityType> = {
    entity: EntityTypeMap[E];
    mode: EntityDetailMode;
};

export interface ExtendedEntityParameters<E extends EntityType> extends EntityModifyParameters<E> {
    oldEntity: EntityTypeMap[E] | null;
}


export namespace ChangeLog {
    type InterpolateData = {
        name: string;
        type: string;
        value: string;
    };

    export type Interpolate = {
        text: string;
        type: 'interpolate';
        data: InterpolateData[];
    }

    type RegularMessage = {
        type: 'text';
        value: string;
    }

    type ScriptMessage = {
        type: 'script';
        oldScriptValue?: string;
        currentScriptValue?: string;
    }

    export type MessageType = RegularMessage | ScriptMessage | Interpolate;

    type ChangeLogAction = 'add' | 'update' | 'delete' | 'cascade';

    type BaseChangeLogData = {
        itemId: string;
        messages: MessageType[];
        action: ChangeLogAction;
        title: RegularMessage | Interpolate;
    };
    interface CascadeChangeLogData extends BaseChangeLogData {
        action: 'cascade';
    }
    export interface AddChangeLogData extends BaseChangeLogData {
        action: 'add';
    }
    export interface IncludePreviousChangeLogData<T extends Record<string, unknown> = Record<string, unknown>> extends BaseChangeLogData {
        previousItem: T;
        action: 'update' | 'delete';
    }
    export type ChangeLogChange<T extends Record<string, unknown> = Record<string, unknown>> = AddChangeLogData | IncludePreviousChangeLogData<T> | CascadeChangeLogData;
    export type ChangeLogCategory<T extends Record<string, unknown> = Record<string, unknown>, PreviousItem extends Record<string, unknown> = Record<string, unknown>> = {
        name: string;
        icon: string;
        changes: ChangeLogChange<PreviousItem>[];
    } & {
        extraData?: T;
    }
    export type EntityChangesDetail = {
        name?: string;
        icon?: string;
        getChanges: () => ChangeLogCategory[];
        changesCategoryComponent: () => Promise<VueComponent>;
    };
}

export interface EntityDetailRouteConfig<E extends EntityType> extends EntityDetailRouteConfigBase {
    meta: EntityDetailRouteConfigBase['meta'] & {
        /** to be inserted into the route meta for type discriminination */
        entityType: E;
        /** should be called in the returned route's `beforeEnter`. the resolved value will be  */
        getEntity: (to: Route, from?: Route, mode?: EntityDetailMode) => Promise<{ entity: EntityTypeMap[E], originalEntity?: EntityTypeMap[E] | null }>;
        /** to be inserted into route meta, so it can be called by EDC or ChangeDetection */
        saveEntity: (payload: ExtendedEntityParameters<E>) => Promise<void | SaveResult>;
        /** custom function to gather active changes to the current entity data.
         * will appear as a TAB within the changes dialog. Name and Icon properties will apply to the name and icon of the tab.
         *
         * Defualt name is "Changes" and icon is "mdi-history"
         *
         * */
        entityChangesDetail?: ChangeLog.EntityChangesDetail;
        /** to be inserted into route meta, so it can be called by EDC on creation */
        createEntity?: (payload: EntityModifyParameters<E>) => Promise<void | SaveResult>;
        /** to be inserted into route meta, so it can be called by EDC on creation */
        forkEntity?: (payload: ExtendedEntityParameters<E>) => Promise<void | SaveResult>;
        canFork?: boolean | ((router: VueRouter) => boolean | Promise<boolean>);
        /** when using _isEqual is not enough to detect actual unsaved changes the entity can define its own methods for detecting saved changes */
        hasChanges?: HasChanges;
        /**
         * if specified, this function will be used to determine the route params for fetching the entity. otherwise, all route params will be used.
         *
         * this is useful for entities which have child routes with their own params
         */
        routeParamsForFetch?: (route: Route) => Record<string, string>;
    };
}

/**
 * a route that expects a component that directly or indirectly renders the RouteEntityDetail component, without hitting another RouterView.
 * this ensures the route config is passed via props to the RouteEntityDetail.
 *
 * All children routes are expected to be EDC tabs.
 */
export function entityTabsRoute (route: EntityTabsRoute): RouteConfig {
    return {
        ...route,
        props: {
            tabsRoute: route,
            ...route.props,
        },
    };
}

export function isEntityDetailRoute (route: RouteConfig): route is EntityDetailRouteConfig<EntityType> {
    return route.meta?.saveEntity;
}

export async function getEntityNavGuard<E extends EntityType> (
    routeConfig: EntityDetailRouteConfig<E>,
    to: Route,
    from: Route,
    next: NavigationGuardNext
) {
    try {
        const { entity, originalEntity } = await routeConfig.meta.getEntity(to, from, (to.query.mode || 'view') as EntityDetailMode);
        accessor.initializeMaintenance({
            type: routeConfig.meta.entityType,
            entity,
            originalEntity,
        });
        next();
    } catch (err) {
        // if the error is occuring on initial page load, then giving an err to the cb will prevent the entire page from loading.
        // we can detect that by checking the `from.matched` to see if we're coming from an existing route or not.
        if (from.matched.length > 0) {
            // safe to give err
            next(err);
        } else {
            // not safe to give err, redirect to main page
            next({ name: 'main' });
        }
    }
}

/**
 * injects the route config into the props for the component.
 *
 * also adds a navigation guard to ensure the entity data is loaded before rendering components.
 */
export function entityDetailRoute<E extends EntityType> (routeCfg: EntityDetailRouteConfig<E>): RouteConfig {
    return {
        ...routeCfg,
        props: {
            entityDetailRoute: routeCfg,
            ...routeCfg.props,
        },
        beforeEnter: (to, from, next) => getEntityNavGuard(routeCfg, to, from, next),
    };
}

interface LegacyEntityDetailRouteConfig extends EntityDetailRouteConfigBase {
    component: typeof RouteEntityDetail;
    redirect: RedirectOption,
    children: {
        path: string,
        name: string,
        component?: Component,
        meta: {
            tab: {
                label: string,
                icon: string,
            },
        },
    }[];
}

/**
 * @deprecated we're trying to update all the management pages that use this
 */
export function legacyEntityDetailRoute (route: LegacyEntityDetailRouteConfig): RouteConfig {
    return {
        ...route,
        props: {
            entityDetailRoute: route,
        },
    };
}
