/* eslint-disable complexity */
/* eslint-disable max-depth */
/* eslint-disable max-lines */
import type { ScreenDesigner } from '../../types/mes/screen-designer/screen-design';
import { v4 as uuidv4 } from 'uuid';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

export type GridCellStylingWithArgoCellData = {
    subGridIds: string[];
    gridLevelColor: ScreenDesigner.GridLevelColorOption;
    regulars: {
        id: string;
        style: ScreenDesigner.RegularGridCellStyle;
        argoCell: Latest.Screen.Cells.AppletCell | null;
        elevation: number;
        marginsAsNumbers: {
            top: number;
            left: number;
            right: number;
            bottom: number;
        };
    }[];
};

export type GridContainerStyling = {
    style: ScreenDesigner.SubGridCellStyle | ScreenDesigner.RootGridStyle;
    elevation: number;
};

const colors: {
    previousGridLevelColor: ScreenDesigner.GridLevelColorOption;
    cfg: ScreenDesigner.GridLevelColor;
}[] = [
    {
        cfg: {
            color: 'yellow',
            hex: '#f7e682',
        },
        previousGridLevelColor: 'purple',
    },
    {
        cfg: {
            color: 'blue',
            hex: '#86c8f4',
        },
        previousGridLevelColor: 'yellow',
    },
    {
        cfg: {
            color: 'purple',
            hex: '#e396de',
        },
        previousGridLevelColor: 'blue',
    },
];

const generateRegularCellStyle = (payload: {
    gridArea: string;
    backgroundColor: string;
    opacity: number;
    margin: Latest.Screen.Designer.MarginPaddingBase;
    padding: Latest.Screen.Designer.MarginPaddingBase;
    border: Latest.Screen.Designer.BorderStyle;
    borderRadius: Latest.Screen.Designer.BorderRadius;
}): ScreenDesigner.RegularGridCellStyle => ({
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    opacity: payload.opacity,
    justifyContent: 'center',
    gridArea: payload.gridArea,
    borderRadius: payload.borderRadius ?? 0,
    marginTop: `${payload.margin.top}px`,
    paddingTop: `${payload.padding.top}px`,
    marginLeft: `${payload.margin.left}px`,
    paddingLeft: `${payload.padding.left}px`,
    marginRight: `${payload.margin.right}px`,
    backgroundColor: payload.backgroundColor,
    paddingRight: `${payload.padding.right}px`,
    marginBottom: `${payload.margin.bottom}px`,
    paddingBottom: `${payload.padding.bottom}px`,
    borderTop: `${payload.border.top.width}px ${payload.border.top.style} ${payload.border.top.color}`,
    borderLeft: `${payload.border.left.width}px ${payload.border.left.style} ${payload.border.left.color}`,
    borderRight: `${payload.border.right.width}px ${payload.border.right.style} ${payload.border.right.color}`,
    borderBottom: `${payload.border.bottom.width}px ${payload.border.bottom.style} ${payload.border.bottom.color}`,
});

export const generateSubGridCellStyle = (payload: {
    gridArea: string;
    rows: Latest.Screen.Designer.ScreenRow[];
    cols: Latest.Screen.Designer.ScreenColumn[];
    linkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    margin: Latest.Screen.Designer.MarginPaddingBase;
    padding: Latest.Screen.Designer.MarginPaddingBase;
    borderRadius: Latest.Screen.Designer.BorderRadius;
    backgroundColor: string;
    border: Latest.Screen.Designer.BorderStyle;
}): ScreenDesigner.SubGridCellStyle => ({
    gap: '0px 0px',
    display: 'grid',
    overflow: 'hidden',
    position: 'relative',
    gridArea: payload.gridArea,
    borderRadius: payload.borderRadius ?? 0,
    marginTop: `${payload.margin.top}px`,
    paddingTop: `${payload.padding.top}px`,
    marginLeft: `${payload.margin.left}px`,
    paddingLeft: `${payload.padding.left}px`,
    marginRight: `${payload.margin.right}px`,
    backgroundColor: payload.backgroundColor,
    marginBottom: `${payload.margin.bottom}px`,
    paddingRight: `${payload.padding.right}px`,
    paddingBottom: `${payload.padding.bottom}px`,
    gridTemplateRows: payload.rows.map(r => `${r.weight}fr`).join(' '),
    gridTemplateColumns: payload.cols.map(c => `${c.weight}fr`).join(' '),
    borderTop: `${payload.border.top.width}px ${payload.border.top.style} ${payload.border.top.color}`,
    gridTemplateAreas: generatePattern(payload.rows.length, payload.cols.length, payload.linkedGridCells),
    borderLeft: `${payload.border.left.width}px ${payload.border.left.style} ${payload.border.left.color}`,
    borderRight: `${payload.border.right.width}px ${payload.border.right.style} ${payload.border.right.color}`,
    borderBottom: `${payload.border.bottom.width}px ${payload.border.bottom.style} ${payload.border.bottom.color}`,
});

export const generatePattern = (rowNum: number, colNum: number, linkedGridCells: Latest.Screen.Designer.LinkedGridCell[]) => {
    let pattern = '';
    for (let rIdx = 0; rIdx < rowNum; rIdx++) {
        pattern += '"';
        for (let cIdx = 0; cIdx < colNum; cIdx++) {
            const lgc = linkedGridCells.find(lgc => lgc.coordinates.find(coord => coord.row === rIdx && coord.column === cIdx));
            if (cIdx === colNum - 1) {
                pattern += `lgc-${lgc?.id || '.'}" `;
            } else {
                pattern += `lgc-${lgc?.id || '.'} `;
            }
        }
    }
    return pattern;
};

export const produceCellStyling = (payload: {
    filterdLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    groupedCellIds?: string[];
    rootGroupedCellId?: string | null;
    targetedGridId?: string | null;
    previousGridLevelColor?: ScreenDesigner.GridLevelColorOption | null;
    hoveringGridId?: string | null;
    hoveringCellId?: string | null;
    useActualGridCellBackgroundColor?: boolean;
    backgroundColorControlVarValues?: {
        lgcId: string;
        varValue: string;
    }[];
    argoCells?: Latest.Screen.Cells.AppletCell[];
    pageNumbers: ScreenDesigner.PageNumberVarMap[];
}): GridCellStylingWithArgoCellData => {
    let color: ScreenDesigner.GridLevelColor = { color: 'yellow', hex: '#f7e682' };
    const ret: GridCellStylingWithArgoCellData = {
        subGridIds: [],
        regulars: [],
        gridLevelColor: color.color,
    };

    if (payload.previousGridLevelColor) {
        const nextColor = colors.find(c => payload.previousGridLevelColor && c.previousGridLevelColor === payload.previousGridLevelColor)?.cfg;
        if (nextColor) {
            color = nextColor;
            ret.gridLevelColor = color.color;
        }
    }
    const {
        regulars,
        subGridIds,
    } = payload.filterdLinkedGridCells.reduce((acc, lgc) => {
        if (lgc.type === 'subGrid') {
            if (!acc.subGridIds.find(c => c === lgc.id) && lgc.pageNumber === null) {
                acc.subGridIds.push(lgc.id);
                return acc;
            }
            if (lgc.pageNumber !== null && payload.pageNumbers.find(pn => pn.pageSequenceRootGridId === lgc.parentGridId && pn.pageNumberVarVal === lgc.pageNumber)) {
                acc.subGridIds.push(lgc.id);
                return acc;
            }
        }
        if (lgc.type === 'regular' && !acc.regulars.find(reg => reg.id === lgc.id)) {
            let backgroundColor = color.hex;
            if (payload.rootGroupedCellId && payload.rootGroupedCellId === lgc.id) {
                backgroundColor = 'green';
            } else if (payload.groupedCellIds?.find(gCId => gCId === lgc.id)) {
                backgroundColor = 'red';
            } else if (payload.useActualGridCellBackgroundColor) {
                if (payload.backgroundColorControlVarValues) {
                    const varData = payload.backgroundColorControlVarValues.find(v => v.lgcId === lgc.id);
                    if (varData && varData.varValue) {
                        backgroundColor = varData.varValue;
                    } else {
                        backgroundColor = lgc.backgroundColor;
                    }
                } else {
                    backgroundColor = lgc.backgroundColor;
                }
            }

            let opacity = 1;
            if (payload.targetedGridId && payload.targetedGridId !== lgc.parentGridId) {
                opacity = 0.5;
            } else if (payload.hoveringGridId && payload.hoveringGridId === lgc.parentGridId) {
                opacity = 0.5;
            } else if (payload.hoveringCellId && payload.hoveringCellId === lgc.id) {
                opacity = 0.5;
            }

            acc.regulars.push({
                id: lgc.id,
                elevation: lgc.elevation,
                argoCell: payload.argoCells?.find(ac => ac.id === lgc.argoCellId) || null,
                style: generateRegularCellStyle({
                    opacity,
                    backgroundColor,
                    border: lgc.border,
                    borderRadius: lgc.borderRadius,
                    margin: lgc.margin,
                    padding: lgc.padding,
                    gridArea: `lgc-${lgc.id}`,
                }),
                marginsAsNumbers: {
                    top: lgc.margin.top,
                    left: lgc.margin.left,
                    right: lgc.margin.right,
                    bottom: lgc.margin.bottom,
                },
            });
        }
        return acc;
    }, {
        regulars: [],
        subGridIds: [],
    } as Omit<GridCellStylingWithArgoCellData, 'gridLevelColor'>);

    ret.regulars = regulars;
    ret.subGridIds = subGridIds;
    return ret;
};

export const generateLinkedGridCellCfg = (payload: {
    row: number;
    column: number;
    parentGridId: string | null;
}): Latest.Screen.Designer.RegularGridCell => ({
    id: uuidv4(),
    elevation: 0,
    argoCellId: '',
    type: 'regular',
    backgroundColor: '#00000000',
    backgroundColorControlVar: null,
    parentGridId: payload.parentGridId,
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
    padding: { top: 0, right: 0, bottom: 0, left: 0 },
    border: {
        top: { color: 'black', style: 'none', width: 1 },
        left: { color: 'black', style: 'none', width: 1 },
        right: { color: 'black', style: 'none', width: 1 },
        bottom: { color: 'black', style: 'none', width: 1 },
    },
    coordinates: [ { row: payload.row, column: payload.column } ],
});

export const generateDefaultLinkedCells = (payload: {
    rows: Latest.Screen.Designer.ScreenRow[];
    cols: Latest.Screen.Designer.ScreenColumn[];
    allLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    cellId: string | null;
    isPageSequenceRoot?: boolean;
}): Latest.Screen.Designer.LinkedGridCell[] => {
    const newLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[] = [];
    if (payload.isPageSequenceRoot) {
        const pageLgcs = payload.allLinkedGridCells.filter((lgc): lgc is Latest.Screen.Designer.SubGridCell => lgc.parentGridId === payload.cellId && lgc.type === 'subGrid');
        pageLgcs.forEach(pageLgc => {
            newLinkedGridCells.push(pageLgc);
            newLinkedGridCells.push(...generateDefaultLinkedCells({
                allLinkedGridCells: payload.allLinkedGridCells,
                cellId: pageLgc.id,
                cols: pageLgc.columns,
                rows: pageLgc.rows,
            }));
        });
        return newLinkedGridCells;
    } else {
        payload.rows.forEach((_, rIdx) => {
            payload.cols.forEach((_, cIdx) => {
                const lgc = payload.allLinkedGridCells.find(lgc => lgc.coordinates.find(coord => coord.row === rIdx && coord.column === cIdx) && lgc.parentGridId === payload.cellId);
                if (lgc) {
                    if (!newLinkedGridCells.find(nlgc => nlgc.id === lgc.id)) {
                        newLinkedGridCells.push(lgc);
                        if (lgc.type === 'subGrid') {
                            if (lgc.isPageSequenceRoot) {
                                newLinkedGridCells.push(...generateDefaultLinkedCells({
                                    allLinkedGridCells: payload.allLinkedGridCells,
                                    cellId: lgc.id,
                                    cols: lgc.columns,
                                    rows: lgc.rows,
                                    isPageSequenceRoot: true,
                                }));
                            } else {
                                newLinkedGridCells.push(...generateDefaultLinkedCells({
                                    allLinkedGridCells: payload.allLinkedGridCells,
                                    cellId: lgc.id,
                                    cols: lgc.columns,
                                    rows: lgc.rows,
                                }));
                            }
                        }
                    }
                } else {
                    newLinkedGridCells.push(generateLinkedGridCellCfg({
                        row: rIdx,
                        column: cIdx,
                        parentGridId: payload.cellId,
                    }));
                }
            });
        });
    }

    return newLinkedGridCells;
};

export const generateChildSubGridCells = (payload: {
    subGrid: Latest.Screen.Designer.SubGridCell;
    allLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
}): Latest.Screen.Designer.LinkedGridCell[] => {
    const childSubGridCells = payload.allLinkedGridCells.filter(lgc => lgc.parentGridId === payload.subGrid.id);
    const linkedGridCellsCopy = [ ...payload.allLinkedGridCells.filter(lgc => lgc.id !== payload.subGrid.id && lgc.parentGridId !== payload.subGrid.id), payload.subGrid ];
    payload.subGrid.rows.forEach((_, rIdx) => {
        payload.subGrid.columns.forEach((_, cIdx) => {
            const childSubGridCell = childSubGridCells.find(csgc => csgc.coordinates.find(coord => coord.row === rIdx && coord.column === cIdx));
            if (childSubGridCell) {
                linkedGridCellsCopy.push(childSubGridCell);
            } else {
                linkedGridCellsCopy.push(generateLinkedGridCellCfg({
                    row: rIdx,
                    column: cIdx,
                    parentGridId: payload.subGrid.id,
                }));
            }
        });
    });
    return linkedGridCellsCopy;
};

export const generateContainerStyleRef = (payload: {
    rows: Latest.Screen.Designer.ScreenRow[];
    columns: Latest.Screen.Designer.ScreenColumn[];
    linkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    gridId: string | null;
    useActualGridCellBackgroundColor?: boolean;
    backgroundColorControlVarValues?: {
        lgcId: string;
        varValue: string;
    }[];
}): GridContainerStyling => {
    if (payload.gridId === null) {
        throw new Error('gridId is required');
    }
    const subGrid = payload.linkedGridCells.find(lgc => lgc.id === payload.gridId);
    if (!subGrid || subGrid.type !== 'subGrid') {
        throw new Error('Invalid gridId');
    }
    let backgroundColor = '#00000000';
    if (payload.useActualGridCellBackgroundColor) {
        if (payload.backgroundColorControlVarValues) {
            const varData = payload.backgroundColorControlVarValues.find(v => v.lgcId === subGrid.id);
            if (varData && varData.varValue) {
                backgroundColor = varData.varValue;
            } else {
                backgroundColor = subGrid.backgroundColor;
            }
        } else {
            backgroundColor = subGrid.backgroundColor;
        }
    }

    return {
        elevation: subGrid.elevation,
        style: generateSubGridCellStyle({
            backgroundColor,
            rows: subGrid.rows,
            cols: subGrid.columns,
            border: subGrid.border,
            margin: subGrid.margin,
            padding: subGrid.padding,
            gridArea: `lgc-${subGrid.id}`,
            borderRadius: subGrid.borderRadius,
            linkedGridCells: payload.linkedGridCells.filter(lgc => lgc.parentGridId === payload.gridId),
        }),
    };
};

export const generatePageSequenceRef = (payload: {
    hiddenSlider: boolean,
    pageSequenceRoot: Latest.Screen.Designer.SubGridCell;
    pageGridCssCell: Latest.Screen.Designer.SubGridCell | null;
    useActualGridCellBackgroundColor?: boolean;
    backgroundColorControlVarValues?: {
        lgcId: string;
        varValue: string;
    }[];
}): GridContainerStyling => {
    let backgroundColor = '#00000000';
    if (payload.useActualGridCellBackgroundColor) {
        if (payload.backgroundColorControlVarValues) {
            const varData = payload.backgroundColorControlVarValues.find(v => v.lgcId === payload.pageSequenceRoot.id);
            if (varData && varData.varValue) {
                backgroundColor = varData.varValue;
            } else {
                backgroundColor = payload.pageSequenceRoot.backgroundColor;
            }
        } else {
            backgroundColor = payload.pageSequenceRoot.backgroundColor;
        }
    }

    return {
        style: generateSubGridCellStyle({
            backgroundColor,
            rows: [ { weight: 1 } ],
            cols: [ { weight: 1 } ],
            border: payload.pageSequenceRoot.border,
            margin: payload.pageSequenceRoot.margin,
            borderRadius: 0,
            padding: {
                top: payload.pageSequenceRoot.padding.top,
                left: payload.pageSequenceRoot.padding.left,
                right: payload.pageSequenceRoot.padding.right,
                bottom: payload.hiddenSlider ? payload.pageSequenceRoot.padding.bottom : payload.pageSequenceRoot.padding.bottom + 52, // Statically adding 52px for the page number slider if enabled
            },
            gridArea: `lgc-${payload.pageSequenceRoot.id}`,
            linkedGridCells: payload.pageGridCssCell ? [ payload.pageGridCssCell ] : [],
        }),
        elevation: payload.pageSequenceRoot.elevation,
    };
};

export const generateRootGridStyle = (payload: {
    rows: Latest.Screen.Designer.ScreenRow[];
    elevation: number;
    border: Latest.Screen.Designer.BorderStyle;
    columns: Latest.Screen.Designer.ScreenColumn[];
    backgroundColor: string;
    margin: Latest.Screen.Designer.MarginPaddingBase;
    padding: Latest.Screen.Designer.MarginPaddingBase;
    borderRadius: Latest.Screen.Designer.BorderRadius;
    linkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    backgroundColorControlVarValue: string;
    useBaseGridLayerBackgroundColor: boolean;
}): GridContainerStyling => {

    let backgroundColor = '#00000000';
    if (payload.useBaseGridLayerBackgroundColor) {
        if (payload.backgroundColorControlVarValue) {
            backgroundColor = payload.backgroundColorControlVarValue;
        } else {
            backgroundColor = payload.backgroundColor;
        }
    }

    const baseGridStyle: ScreenDesigner.RootGridStyle = {
        gap: '0px 0px',
        display: 'grid',
        backgroundColor,
        overflow: 'hidden',
        position: 'absolute',
        borderRadius: payload.borderRadius ?? 0,
        paddingTop: `${payload.padding.top}px`,
        paddingLeft: `${payload.padding.left}px`,
        paddingRight: `${payload.padding.right}px`,
        paddingBottom: `${payload.padding.bottom}px`,
        gridTemplateRows: payload.rows.map(r => `${r.weight}fr`).join(' '),
        gridTemplateColumns: payload.columns.map(c => `${c.weight}fr`).join(' '),
        borderTop: `${payload.border.top.width}px ${payload.border.top.style} ${payload.border.top.color}`,
        borderLeft: `${payload.border.left.width}px ${payload.border.left.style} ${payload.border.left.color}`,
        borderRight: `${payload.border.right.width}px ${payload.border.right.style} ${payload.border.right.color}`,
        borderBottom: `${payload.border.bottom.width}px ${payload.border.bottom.style} ${payload.border.bottom.color}`,
        gridTemplateAreas: generatePattern(payload.rows.length, payload.columns.length, payload.linkedGridCells.filter(lgc => lgc.parentGridId === null)),
        inset: `
            ${payload.margin.top}px
            ${payload.margin.right}px
            ${payload.margin.bottom}px
            ${payload.margin.left}px
        `,
    };
    return {
        style: baseGridStyle,
        elevation: payload.elevation,
    };
};

export const createSubGridsForPageSequenceUtil = (payload: {
    lgc: Latest.Screen.Designer.LinkedGridCell;
    argoCell: Latest.Screen.Cells.MacroTargetCell;
    allLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    pageCells: Latest.Screen.Cells.PageCell[];
}): {
    newLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    newPageSequenceRootCell: Latest.Screen.Designer.SubGridCell;
} => {
    const newPageSequenceRootCell: Latest.Screen.Designer.SubGridCell = {
        ...payload.lgc,
        argoCellId: payload.argoCell.id,
        type: 'subGrid',
        pageNumber: null,
        isPageSequenceRoot: true,
        rows: [ { weight: 1 } ],
        columns: [ { weight: 1 } ],
    };
    let allLgcsCopy = [ ...payload.allLinkedGridCells.filter(lgc => lgc.id !== payload.lgc.id), newPageSequenceRootCell ];
    payload.pageCells.forEach((c, i) => {
        const newPagedSubGridCell = createPageSubGrid(c.id, i, newPageSequenceRootCell.id);
        allLgcsCopy = generateChildSubGridCells({
            allLinkedGridCells: allLgcsCopy,
            subGrid: newPagedSubGridCell,
        });
    });
    return {
        newLinkedGridCells: allLgcsCopy,
        newPageSequenceRootCell,
    };
};

export const createPageSubGrid = (argoCellId: string, pageNumber: number, pageSequenceParentGridId: string): Latest.Screen.Designer.SubGridCell => {
    const newPagedSubGridCell: Latest.Screen.Designer.SubGridCell = {
        argoCellId,
        pageNumber,
        id: uuidv4(),
        elevation: 0,
        type: 'subGrid',
        isPageSequenceRoot: false,
        backgroundColor: '#00000000',
        backgroundColorControlVar: null,
        coordinates: [ { row: 0, column: 0 } ],
        rows: [ { weight: 1 }, { weight: 1 } ],
        parentGridId: pageSequenceParentGridId,
        columns: [ { weight: 1 }, { weight: 1 } ],
        margin: { top: 0, right: 0, bottom: 0, left: 0 },
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
        border: {
            top: { color: 'black', style: 'none', width: 1 },
            left: { color: 'black', style: 'none', width: 1 },
            right: { color: 'black', style: 'none', width: 1 },
            bottom: { color: 'black', style: 'none', width: 1 },
        },
    };
    return newPagedSubGridCell;
};

const generateMissingPageSubGridsHelper = (payload: {
    allLinkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    argoScreenCells: Latest.Screen.Cells.AppletCell[];
}): Latest.Screen.Designer.LinkedGridCell[] => {
    let allLgcsCopy = [ ...payload.allLinkedGridCells ];
    payload.allLinkedGridCells.forEach(lgc => {
        if (lgc.type === 'subGrid' && lgc.isPageSequenceRoot) {
            payload.argoScreenCells.filter((argoCell): argoCell is Latest.Screen.Cells.PageCell => argoCell.type === 'page' && argoCell.attrs.parentCellId === lgc.argoCellId).forEach((pageCell: Latest.Screen.Cells.PageCell, i) => {
                if (!payload.allLinkedGridCells.find(pageSubGrid => pageSubGrid.argoCellId === pageCell.id)) {
                    allLgcsCopy = generateChildSubGridCells({
                        allLinkedGridCells: allLgcsCopy,
                        subGrid: createPageSubGrid(pageCell.id, i, lgc.id),
                    });
                }
            });
        }
    });
    return allLgcsCopy;
};

export const generateMissingPageSubGrids = (incomingCfg: Latest.Screen.Designer.GridLayoutAspectRatios, argoCells: Latest.Screen.Cells.AppletCell[]): Latest.Screen.Designer.GridLayoutAspectRatios => {
    const newGridLayoutAspectRatios = { ...incomingCfg };

    Object.keys(newGridLayoutAspectRatios).forEach(aspectRatio => {
        const gridLayout = newGridLayoutAspectRatios[aspectRatio as ScreenDesigner.AspectRatioType];
        const allLgcsCopy = generateMissingPageSubGridsHelper({
            argoScreenCells: argoCells,
            allLinkedGridCells: gridLayout.linkedGridCells,
        });
        gridLayout.linkedGridCells = allLgcsCopy;
    });
    return newGridLayoutAspectRatios;
};

export const generateDefaultGridLayout = (type: ScreenDesigner.AspectRatioType): Latest.Screen.Designer.GridLayout => ({
    elevation: 0,
    rows: [ { weight: 1 } ],
    columns: [ { weight: 1 } ],
    enabled: type === 'desktop',
    backgroundColor: '#00000000',
    backgroundColorControlVar: null,
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
    padding: { top: 0, right: 0, bottom: 0, left: 0 },
    linkedGridCells: [ generateLinkedGridCellCfg({
        row: 0,
        column: 0,
        parentGridId: null,
    }) ],
    border: {
        top: { color: 'black', style: 'none', width: 1 },
        left: { color: 'black', style: 'none', width: 1 },
        right: { color: 'black', style: 'none', width: 1 },
        bottom: { color: 'black', style: 'none', width: 1 },
    },
});

export const generateGridContainerStyling = (payload: {
    rows: Latest.Screen.Designer.ScreenRow[];
    elevation: number;
    border: Latest.Screen.Designer.BorderStyle;
    gridId: string | null;
    columns: Latest.Screen.Designer.ScreenColumn[];
    baseLayerBackgroundColor: string;
    linkedGridCells: Latest.Screen.Designer.LinkedGridCell[];
    useActualBackGroundColors: boolean;
    borderRadius: Latest.Screen.Designer.BorderRadius;
    baseLayerMargin: Latest.Screen.Designer.MarginPaddingBase;
    baseLayerPadding: Latest.Screen.Designer.MarginPaddingBase;
    backgroundColorControlVarValue: string;
    pageNumberVarMap: ScreenDesigner.PageNumberVarMap[]
}) => {
    if (payload.gridId) {
        const subGrid = payload.linkedGridCells.find(lgc => lgc.id === payload.gridId);
        if (subGrid && subGrid.type === 'subGrid' && subGrid.isPageSequenceRoot) {
            const currentPageNum = payload.pageNumberVarMap.find(p => p.pageSequenceRootGridId === payload.gridId);
            const pageGridCssCell = payload.linkedGridCells.find(lgc => lgc.type === 'subGrid' &&
                lgc.parentGridId === currentPageNum?.pageSequenceRootGridId &&
                lgc.pageNumber === currentPageNum?.pageNumberVarVal) || null;
            if (pageGridCssCell && pageGridCssCell.type !== 'subGrid') {
                throw new Error('Page grid css cell is not a sub grid');
            }
            return generatePageSequenceRef({
                pageGridCssCell,
                pageSequenceRoot: subGrid,
                hiddenSlider: currentPageNum?.btnSize === 'hidden',
                useActualGridCellBackgroundColor: payload.useActualBackGroundColors,
            });
        }
        return generateContainerStyleRef({
            rows: payload.rows,
            gridId: payload.gridId,
            columns: payload.columns,
            linkedGridCells: payload.linkedGridCells,
            useActualGridCellBackgroundColor: payload.useActualBackGroundColors,
            backgroundColorControlVarValues: payload.backgroundColorControlVarValue ? [ { lgcId: payload.gridId, varValue: payload.backgroundColorControlVarValue } ] : [],
        });
    }
    return generateRootGridStyle({
        rows: payload.rows,
        border: payload.border,
        columns: payload.columns,
        elevation: payload.elevation,
        margin: payload.baseLayerMargin,
        padding: payload.baseLayerPadding,
        borderRadius: payload.borderRadius ?? 0,
        linkedGridCells: payload.linkedGridCells,
        backgroundColor: payload.baseLayerBackgroundColor,
        useBaseGridLayerBackgroundColor: payload.useActualBackGroundColors,
        backgroundColorControlVarValue: payload.backgroundColorControlVarValue,
    });
};
