import { textCellAttrsSchema } from './text.zod';
import z from 'zod';
import { cellCfgBaseSchema } from './cell-base.zod';

export const dateTimeCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('datetime'),
    attrs: textCellAttrsSchema.omit({
        content: true,
    }).extend({
        format: z.enum([ 'date', 'time', 'datetime' ]),
    }),
});
