import { z } from 'zod';
import { pageCfgSchema } from '../cell-types/paged.zod';
import { varpConfigBase } from './var-provider.zod';

const pageProviderAttrsSchema = z.object({
    pageCfg: pageCfgSchema,
    displayRuleOutputVarColor: z.string(),
    disableRuleOutputVar: z.string(),
    displayRuleOutputVarIcon: z.string(),
    advanceRuleOutputVar: z.string(),
});

export const pageProviderSchema = varpConfigBase.extend({
    type: z.literal('page'),
    attrs: pageProviderAttrsSchema,
});
