
import { computed, defineComponent, watch } from 'vue';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import type { FeatureFlags } from '@redviking/argonaut-core-ui/src/routing/router.store';
import { onClientErrorFallback } from './util/gql-client';
import { Notify } from './notifications';

export default defineComponent({
    name: 'App',
    setup () {
        // test if k8s dashboard is available
        watch(computed(() => accessor.auth.currentUser), () => {
            fetch('/k8s-dashboard/').then(response => {
                const chartNamespace = response.headers.get('chart-namespace') || '';
                accessor.setChartNamespace(chartNamespace);
            });
        });

        fetch('/feature-flags.json').then<FeatureFlags>(response => response.json()).then(featureFlags => {
            accessor.setFeatureFlags(featureFlags);
        })
        .catch(err => {
            console.error('Failed to fetch feature flags', err);
        });

        onClientErrorFallback((error) => {
            Notify.error(error);
        });
    },
    async created () {
        if (navigator.serviceWorker) {
            navigator.serviceWorker.register('/argo-sw.js', {
                // scope SHOULD work but it doesn't
                // scope: '/file', // only intercept requests to /file
            }).then(registration => {
                registration.update(); // ensures the service worker is updated on first load
            }).catch(err => {
                console.error('Service Worker registration failed', err);
                setTimeout(() => Notify.error('Failed to register service worker, media files will be unavailable'), 2000); // timeout because the notification won't show before the components are mounted
            });
        } else if (location.protocol === 'https:') {
            setTimeout(() => Notify.error('Failed to register service worker, media files will be unavailable'), 2000); // timeout because the notification won't show before the components are mounted
        } else {
            setTimeout(() => Notify.error('HTTPS is required to access media files'), 2000); // timeout because the notification won't show before the components are mounted
        }

        // A "silent" refresh in case already authenticated
        await accessor.auth.refreshToken();
    },
});

