import { z } from 'zod';
import { pageCfgSchema, pagedResetConditionSchema } from '../cell-types/paged.zod';
import { varpConfigBase } from './var-provider.zod';

export const pagedProviderSchema = varpConfigBase.extend({
    type: z.literal('paged'),
    attrs: z.object({
        pageNumberVar: z.string(),
        resetConditions: z.array(pagedResetConditionSchema),
        btnSize: z.enum([ 'small', 'medium', 'large', 'x-large', 'hidden' ]),
        pageCfg: z.array(pageCfgSchema),
    }),
});
