
import {
    PropType,
    computed,
    defineComponent,
    ref,
} from 'vue';
import JSONDiff from './JSONDiff.view.vue';
import { useCoreAccessor } from 'src/store/use-core-accessor';
import { EntityTypeMap } from 'types';
import cloneDeep from 'lodash.clonedeep';
import { ChangeLog } from './entity-route-util';
import EntityChangesDetailView from './EntityChangesDetail.view.vue';

type TabData = {
    icon: string;
    label: string;
    isDefault: boolean;
};

export const ChangesDialog = defineComponent({
    name: 'ChangesDialog',
    components: {
        JSONDiff,
        EntityChangesDetailView,
    },
    props: {
        value: { type: Boolean, required: true },
        entityChangesDetail: {
            default: null,
            required: false,
            type: Object as PropType<ChangeLog.EntityChangesDetail>,
        },
    },
    setup (props, { emit }) {
        const accessor = useCoreAccessor();

        const tabs = computed<TabData[]>(() => {
            const result: TabData[] = [];
            if (props.entityChangesDetail) {
                result.push({
                    isDefault: false,
                    label: props.entityChangesDetail.name || 'Changes',
                    icon: props.entityChangesDetail.icon || 'mdi-history',
                });
            }
            result.push({
                label: 'JSON',
                isDefault: true,
                icon: 'mdi-code-braces',
            });
            return result;
        });

        const showUndoDialog = ref(false);

        return {
            tabs,
            showUndoDialog,
            activeTabIdx: ref(0),
            handleUndo () {
                if (accessor.originalEntity) {
                    accessor.setPageEntity({ type: accessor.originalEntity.type as keyof EntityTypeMap, entity: cloneDeep(accessor.originalEntity.entity) });
                    accessor.appletDesign.reset();
                    showUndoDialog.value = false;
                    emit('input', false);
                }
            },
        };
    },
});
export default ChangesDialog;

