import z from 'zod';
import { baseMacroCfgSchema } from './base-macro.zod';
import { pagedAttrsSchemaDef } from '../cell-types/paged.zod';

export const pagedMacroCfgSchema = baseMacroCfgSchema.extend({
    type: z.literal('paged'),
    attrs: z.object({
        pagedAttrs: pagedAttrsSchemaDef,
    }),
});
