import { z } from 'zod';
import { varNameSchema } from '../../var.zod';
import { varpConfigBase } from './var-provider.zod';

export const sliceProviderSchema = varpConfigBase.extend({
    type: z.literal('slice'),
    attrs: z.object({
        inputVar: varNameSchema,
        sliceStart: z.number(),
        sliceEnd: z.number().optional(),
        outputVar: varNameSchema,
    }),
});
