import { z } from 'zod';
import { macroTypeEnum } from '../macros/base-macro.zod';
import { varpConfigBase } from './var-provider.zod';

export const macroVarpTargetSchema = varpConfigBase.extend({
    type: z.literal('macroTarget'),
    attrs: z.object({
        macroType: macroTypeEnum,
        macroId: z.string(),
    }),
});
