import { z } from 'zod';
import { fileListItemSchema } from '../types/file-service';

export const acceptedMediaMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'image/svg+xml',
    'video/mp4',
    'video/webm',
    'video/ogg',
    'application/pdf',
];

const MediaTypeSchema = z.enum([ 'external', 'internal' ]);

export type MediaType = z.infer<typeof MediaTypeSchema>;


export const baseMediaUploadValueSchema = z.object({
    type: MediaTypeSchema,
});

export type BaseMediaUploadValue = z.infer<typeof baseMediaUploadValueSchema>;

export const baseInternalMediaObjectSchema = z.object({
    type: z.literal('internal'),
});
export type BaseInternalMediaObject = z.infer<typeof baseInternalMediaObjectSchema>;

export const uploadedInternalMediaObjectSchema = baseInternalMediaObjectSchema.extend({
    file: fileListItemSchema,
});
export type UploadedInternalMediaObject = z.infer<typeof uploadedInternalMediaObjectSchema>;

export const externalMediaObjectSchema = z.object({
    type: z.literal('external'),
    url: z.string(),
});

export type ExternalMediaObject = z.infer<typeof externalMediaObjectSchema>;


export const mediaObjectSchema = z.discriminatedUnion('type', [
    uploadedInternalMediaObjectSchema,
    externalMediaObjectSchema,
]);

export type MediaObject = z.infer<typeof mediaObjectSchema>;

export function removeFileExtension (filename: string): string {
    return filename.replace(/\.[^/.]+$/ui, '');
}

