import { z } from 'zod';
import { pagedMacroCfgSchema } from './macros/paged.zod';
import { processMacroCfgSchema } from './macros/process.zod';
import { materialLookupCfgSchema } from './macros/material-lookup.zod';

export const macroSchema = z.lazy(() => z.discriminatedUnion('type', [
    pagedMacroCfgSchema,
    processMacroCfgSchema,
    materialLookupCfgSchema,
]));
