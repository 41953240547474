import { z } from 'zod';
import { cellSchema } from './cells.zod';
import { macroSchema } from './macros.zod';
import { varpSchema } from './var-providers.zod';

const rowSchema = z.object({
    weight: z.number().default(1),
});

const columnSchema = z.object({
    weight: z.number().default(1),
});

const coordinateSchema = z.object({
    row: z.number().default(0),
    column: z.number().default(0),
});

const marginPaddingBaseSchema = z.object({
    top: z.number().default(0),
    right: z.number().default(0),
    bottom: z.number().default(0),
    left: z.number().default(0),
});

const borderSchema = z.object({
    width: z.number().default(0),
    color: z.string().default('black'),
    style: z.string().default('none'),
});

const borderStyleSchema = z.object({
    top: borderSchema,
    bottom: borderSchema,
    left: borderSchema,
    right: borderSchema,
});

export const baseGridCellSchema = z.object({
    elevation: z.number().default(0),
    cssCellId: z.string(), // TODO: Remove this and just use the cell id as the gridArea. Attempted this once, was having trouble rendering the cells in the correct spots within the grid
    id: z.string().uuid(),
    argoCellId: z.string().default(''),
    border: borderStyleSchema,
    margin: marginPaddingBaseSchema,
    padding: marginPaddingBaseSchema,
    coordinates: z.array(coordinateSchema),
    // eslint-disable-next-line newline-per-chained-call
    parentGridId: z.string().uuid().nullable().default(null),
    backgroundColor: z.string().default('#00000000'),
    backgroundColorControlVar: z.string().nullable().default(null),
});

export const regularGridCellSchema = baseGridCellSchema.extend({
    type: z.literal('regular'),
});

export const subGridCellSchema = baseGridCellSchema.extend({
    type: z.literal('subGrid'),
    rows: z.array(rowSchema),
    columns: z.array(columnSchema),
    isPageSequenceRoot: z.boolean().default(false),
    pageNumber: z.number().nullable(),
});

const linkedGridCellSchema = z.discriminatedUnion('type', [ regularGridCellSchema, subGridCellSchema ]);

export const gridLayoutSchema = z.object({
    elevation: z.number().default(0),
    rows: z.array(rowSchema),
    border: borderStyleSchema,
    columns: z.array(columnSchema),
    margin: marginPaddingBaseSchema,
    padding: marginPaddingBaseSchema,
    linkedGridCells: z.array(linkedGridCellSchema),
    backgroundColor: z.string().default('#00000000'),
    backgroundColorControlVar: z.string().nullable().default(null),
});

export const screenSchema = z.object({
    id: z.string().uuid(),
    name: z.string(),
    operatorAuth: z.object({
        /**
         * whether this screen participates in operator auth.
         * @default false
         */
        required: z.boolean().default(false),
    }),
    /**
     * Cells that are used within the screen
     */
    cells: z.array(z.lazy(() => cellSchema)),

    /**
     * The layout of the screen
     */
    gridLayout: gridLayoutSchema,
});
const sparkplugDeviceMetricPermissionsSchema = z.object({
    write: z.boolean(),
});

const sparkplugDeviceMetricSchema = z.object({
    name: z.string(),
    permissions: sparkplugDeviceMetricPermissionsSchema,
});

export const runtimeScriptCfgSchema = z.object({
    id: z.string().uuid(),
    script: z.string(),
    /**
     * disables validation against the "argo lang" grammar.
     *
     * this option is "hidden" and intentionally not made visible to users
     */
    disableGrammarValidation: z.boolean().optional(),
});

export const appletDesignVersionConfigSchema_v1 = z.object({
    header: z.string(),
    /**
     * specifies a list of providers, which provide variables to the config.
     * variables can be defined in terms of other variables.
     * to avoid circular loops, this is an ordered list and variables will only be able to reference earlier defined variables.
     */
    varProviders: z.array(varpSchema),
    /** holds the name of a variable. if set, the config should only be allowed to change when this variable's value changes, or lacks a value entirely */
    lockout: z.string().nullable(),
    screens: z.array(screenSchema),
    /**
     * if null, operator auth in general is not required for this config anywhere
     */
    operatorAuth: z.object({
        /**
         * the provider (from the auth service) that operators should use to authenticate
         * @default 'badge'
         */
        type: z.literal('badge'),
        /**
         * if null, the operator auth will not expire
         * @default null
         */
        expiration: z.object({
            timeoutMinutes: z.number(),
            /**
             * whether re-authentication should only be required after inactivity of `timeoutMinutes`,
             * or just since the last auth
             *
             * @default true
             */
            inactivityOnly: z.boolean().default(true),
        }).nullable().default(null),
    }).nullable(),
    macros: z.array(macroSchema),
    /**
     * The config schema version, should be changed every time there's breaking change in the config typedefs
     */
    schemaVersion: z.literal(1),
    sparkplugDeviceMetrics: z.array(sparkplugDeviceMetricSchema).default([]),
    scripts: z.array(runtimeScriptCfgSchema).default([]),
});

export type AppletDesignVersionConfig_V1 = z.infer<typeof appletDesignVersionConfigSchema_v1>;
