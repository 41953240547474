import { ChangeLog } from 'src/components/EntityDetail';

export const generateInterpolateForOneOutput = (oldOutput: string, newOutput: string): ChangeLog.MessageType => ({
    type: 'interpolate',
    text: 'changed output variable from {oldOutputVar} to {newOutputVar}.',
    data: [
        {
            type: 'code',
            name: 'oldOutputVar',
            value: oldOutput,
        },
        {
            type: 'code',
            name: 'newOutputVar',
            value: newOutput,
        },
    ],
});

// Typically an update message for a variable name would say 'varName' was updated to 'newVarName', but when a provider has mutliple outputs that assumption cannot be made so we have to treat the change as a addition or removal
// However, if distinction of variables were made on some unchanged factor i.e. UUID then this would not be the case
export const generateInterpolatesForMultipleOutputs = (oldVars: string[], newVars: string[]): ChangeLog.MessageType[] => [
    ...newVars.filter(output => !oldVars.includes(output)).map<ChangeLog.MessageType>(output => ({
        type: 'interpolate',
        text: 'added {outputVar}',
        messageId: output,
        data: [
            {
                type: 'code',
                name: 'outputVar',
                value: output,
            },
        ],
    })),
    ...oldVars.filter(output => !newVars.includes(output)).map<ChangeLog.MessageType>(output => ({
        type: 'interpolate',
        text: 'removed {outputVar}',
        messageId: output,
        data: [
            {
                type: 'code',
                name: 'outputVar',
                value: output,
            },
        ],
    })),
];
