import z from 'zod';
import { varConditionSchema } from '../var-providers/conditional.zod';
import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

const dialogCellAttrsSchema = cellCfgAttrsBaseSchema.extend({
    showDialog: varConditionSchema,
});

export const dialogCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('dialog'),
    attrs: dialogCellAttrsSchema,
    modifiers: z.record(z.enum([
        'showDialog',
        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
