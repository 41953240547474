import { z } from 'zod';

export const fileListItemSchema = z.object({
    baseName: z.string(),
    contextId: z.string(),
    key: z.string(),
    eTag: z.string().optional(),
    size: z.number().optional(),
    lastModified: z.union([ z.string().optional(), z.date().optional() ]),
});

export type MediaListItem = z.infer<typeof fileListItemSchema>;
