import z from 'zod';
import { btnCellSchema } from './cell-types/btn.zod';
import { pageCellSchema } from './cell-types/page.zod';
import { textCellSchema } from './cell-types/text.zod';
import { inputCellSchema } from './cell-types/input.zod';
import { mediaCellSchema } from './cell-types/media.zod';
import { timerCellSchema } from './cell-types/timer.zod';
import { pagedCellSchema } from './cell-types/paged.zod';
import { dialogCellSchema } from './cell-types/dialog.zod';
import { processCellSchema } from './cell-types/process.zod';
import { dateTimeCellSchema } from './cell-types/datetime.zod';
import { attrLookupCellSchema } from './cell-types/attr-lookup.zod';
import { macroTargetCellSchema } from './cell-types/macro-target.zod';
import { processMediaCellSchema } from './cell-types/process-media.zod';
import { materialLookupCellSchema } from './cell-types/material-lookup.zod';

// NOTE: a discriminated union requires all options to be of type ZodObject.
export const cellSchema = z.lazy(() => z.discriminatedUnion('type', [
    btnCellSchema,
    pageCellSchema,
    textCellSchema,
    timerCellSchema,
    inputCellSchema,
    mediaCellSchema,
    pagedCellSchema,
    dialogCellSchema,
    processCellSchema,
    dateTimeCellSchema,
    attrLookupCellSchema,
    macroTargetCellSchema,
    processMediaCellSchema,
    materialLookupCellSchema,
]));

