import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

import z from 'zod';
import { baseComparisonSchema, baseOperandSchema, varConditionSchema } from '../var-providers/conditional.zod';
import { ProcessProcessStatusEnum } from '../../../../db';

const processOperandSchema = baseOperandSchema.extend({
    type: z.literal('processId'),
    processId: z.string(),
});

const processStatusOperandSchema = baseOperandSchema.extend({
    type: z.literal('processStatus'),
    expectedStatus: z.enum([
        ProcessProcessStatusEnum.Completed,
        ProcessProcessStatusEnum.Incomplete,
        ProcessProcessStatusEnum.Pending,
    ]),
});

const materialFilterComparisonSchema = baseComparisonSchema.extend({
    type: z.literal('processStatus'),
    operands: z.tuple([
        processOperandSchema,
        processStatusOperandSchema,
    ]),
});

export const materialLookupAttrsSchema = cellCfgAttrsBaseSchema.extend({
    matClassId: z.string(),
    /**
     * when a material result is selected, data will be saved to these local vars
     */
    outputVars: z.object({
        materialId: z.string(),
        sn: z.string(),
        udfs: z.array(z.object({
            udfColumnId: z.string(),
            varName: z.string(),
        })),
    }),
    udfColsToShow: z.array(z.object({
        udfColumnId: z.string(),
        udfName: z.string(),
    })),
    /**
     * all these get ANDed together
     */
    filterConditions: z.array(z.object({
        boolOperation: z.enum([ 'and', 'or' ]),
        comparisons: z.array(materialFilterComparisonSchema),
    })),
    /**
     * the cell will initially perform its query as soon as it can.
     * this condition controls when it re-runs the query.
    */
    refreshCondition: varConditionSchema,
});

export const materialLookupCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('materialLookup'),
    attrs: materialLookupAttrsSchema,
});
