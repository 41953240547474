import { GetRefreshTokenResult } from '../types';
import { apiTrpcHttpClient } from '../../util/api-trpc';

/**
 * Gets the available contexts
 */
export function getAvailableContexts () {
    return apiTrpcHttpClient.auth.token.contexts.query();
}

/**
 * Gets a refresh token
 */
export function getRefreshToken (): Promise<GetRefreshTokenResult> {
    return apiTrpcHttpClient.auth.token.refresh.query();
}

/**
 * Revokes a refresh token
 */
export function refreshTokenRevoke () {
    return apiTrpcHttpClient.auth.token.revoke.mutate();
}

/**
 * Logs into the Auth Service using a Local provider
 */
export function providerLocalLogin (email: string, password: string, contextId: string) {
    return apiTrpcHttpClient.auth.provider.local.login.mutate({
        contextId,
        email: email.toLowerCase().trim(),
        password,
    });
}
