import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';
import { validateUdfData } from '@redviking/argonaut-core-ui/src/udf/udf.util';
import {
    EntityTypeMap,
    type SaveResult,
} from 'types';
import {
    GetMaterialUdfColumnsDocument,
} from 'types/db';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { Notify } from 'src/notifications';
import { type EntityModifyParameters } from 'src/components/EntityDetail';
import { validateAttributeSetting } from '@redviking/argonaut-util/src/attribute-settings/validate-attribute-settings';

export function validateMaterialSelectAcl (): boolean {
    return validateAcls([ 'material_select' ]);
}

export function validateMaterialInsertAcl (): boolean {
    return validateAcls([ 'material_insert' ]);
}

export function validateMaterialUpdateAcl (): boolean {
    return validateAcls([ 'material_update' ]);
}

export function validateMaterialDeleteAcl (): boolean {
    return validateAcls([ 'material_delete' ]);
}

async function validateMaterialUdfData (entityData: EntityTypeMap['material']): Promise <boolean> {

    const { argo_material_udf_schema } = await gqlClient.request({
        document: GetMaterialUdfColumnsDocument,
        variables: {
            materialModelId: entityData.materialModel.id,
        },
    });

    const columns = argo_material_udf_schema.map(i => i.udf_column);

    for (const column of columns) {
        if (column) {
            const data = entityData.udfData.find(i => i.udf_column_id === column.id);
            if (!data && column.validation_required) {
                throw new Error(`Missing required field for UDF Column ${column.name}`);
            }

            try {
                if (data) {
                    validateUdfData(data, column);
                }
            } catch (err) {
                const message = `'${err.message}' on User Defined Field: ${column.name}`;
                throw new Error(message);
            }
        }
    }
    return true;
}

export async function validateMaterial (payload: EntityModifyParameters<'material'>): Promise<void | SaveResult> {
    const {
        entity,
    } = payload;
    if (!entity.serial_number) {
        throw new Error('A Serial Number is Required');
    }
    try {
        await validateMaterialUdfData(entity);
    } catch (error) {
        Notify.error(error);
        throw error;
    }

    for (const setting of entity.materialClass.attributeSettings) {
        const status = validateAttributeSetting(entity.materialAttributes, setting);
        if (status.type === 'error') {
            return {
                status: 'error',
                errorMessage: status.messagePlaintext,
            };
        } else if (status.type === 'warning' && !entity.ignoreSchemaWarning) {
            return {
                status: 'modal',
                modalComponent: () => import(/* webpackChunkName: "material" */ './MaterialSaveWarning.view.vue'),
            };
        }
    }
}

