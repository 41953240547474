import { actionTree, getStoreType, getterTree } from 'typed-vuex';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { apiTrpcHttpClient } from 'src/util/api-trpc';
import { applySparkplugRequirements } from './util/sparkplug-formatter';
import { Notify } from 'src/notifications';
import { CausalError } from '@redviking/causal-error';
import { DeployParam, DeployParamKind, DeployParamValue, getDeployParams } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { AppletDeploymentVersionEntity } from './deployment.entity';
import { appletDesignSchema } from '@redviking/argonaut-util/types/mes/applet-designs/applet-design-config.zod';
import { latestAppletDesignVersionNumber } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

type SparkplugDesignMetric = {
    variableName: string;
    isWritable: boolean;
}

interface SparkplugDeploymentParameter extends BaseDeploymentParameter {
    type: 'sparkplug';
    sparkplugMetrics: SparkplugDesignMetric[];
}

type BaseDeploymentParameter = {
    name: string;
    type: 'sparkplug' | 'location';
}

/**
 * when deployment params are initialized in a deployment, this
 * function determines their initial values.
 */
export function getInitialDeployParamValue (deployParam: DeployParam): DeployParamValue {
    if (deployParam.deployParamKind === DeployParamKind.localVarValue) {
        return {
            kind: DeployParamKind.localVarValue,
            val: deployParam.type === 'num' ? Number(deployParam.initialVal) : String(deployParam.initialVal),
        };
    } else if (deployParam.deployParamKind === DeployParamKind.location) {
        return {
            kind: DeployParamKind.location,
            val: '',
        };
    } else if (deployParam.deployParamKind === DeployParamKind.sparkplugNodeCfg) {
        return {
            kind: DeployParamKind.sparkplugNodeCfg,
            val: {
                deviceId: '',
                groupId: '',
                nodeId: '',
                version: '',
            },
        };
    } else {
        // @ts-expect-error - ensures cases are implemented
        const unknownKind: string = deployParam.deployParamKind;
        throw new Error(`Unknown deploy param kind: ${unknownKind}`);
    }
}

const actions = actionTree({ state: {} }, {
    async setLinkedDesignVersions (_, designVerIds: string[]) {
        const deploymentEntity = accessor.entityAsType('appletDeployment');
        const designVersions = await apiTrpcHttpClient.applet.designVersion.getVersionsFromIds.query({
            designVerIds,
        }).catch(err => {
            const cerr = CausalError.from({
                message: 'Failed to load design versions',
                cause: err,
            }, { dedupeTrivialCause: true });
            Notify.error(cerr);
            throw cerr;
        });

        const deployParamVals = { ...deploymentEntity?.config?.deployParamVals };

        if (deploymentEntity) {
            /** for tracking deployment params whose value is defined on the deployment config level. keyed by id */
            const neededDeployParams: Record<string, DeployParam> = {};

            const linkedDesignVersions: AppletDeploymentVersionEntity['linkedDesignVersions'] = designVersions.map(dv => {
                const existingLdv = deploymentEntity.linkedDesignVersions.find(ldv => ldv.designVersion.id === dv.id);
                if (existingLdv) {
                    for (const deployParam of existingLdv.deployParams || []) {
                        if (!existingLdv.config.deployParamVals[deployParam.deployParamId]) { // if not on the ldv, the param value is on the deployment
                            neededDeployParams[deployParam.deployParamId] = deployParam; // track this param
                        }
                    }
                    return existingLdv;
                } else {
                    // this ldv is new
                    const designCfg = dv.config;
                    const deployParams = getDeployParams(designCfg, appletDesignSchema);

                    const newLdv: AppletDeploymentVersionEntity['linkedDesignVersions'][number] = {
                        // initial config
                        config: {
                            sparkplugNodeExpose: null,
                            deployParamVals: {},
                        },
                        deployParams,
                        designVersion: {
                            id: dv.id,
                            name: dv.design.name,
                            designId: dv.design.id,
                            versionNumber: dv.versionNumber,
                            screens: designCfg.schemaVersion === latestAppletDesignVersionNumber ? designCfg.screens : [],
                        },
                    };

                    // initial sparkplugNodeExpose
                    if (dv.config.sparkplugDeviceMetrics && dv.config.sparkplugDeviceMetrics.length) {
                        newLdv.config.sparkplugNodeExpose = {
                            designNameAsDeviceId: applySparkplugRequirements(dv.design.name),
                            vars: dv.config.sparkplugDeviceMetrics.map(m => ({
                                metricName: applySparkplugRequirements(m.name),
                                varName: m.name,
                                isWritable: m.permissions.write,
                            })),
                        };
                    }

                    // initial deployParamVals
                    for (const deployParam of deployParams) {
                        if (!deployParamVals[deployParam.deployParamId]) {
                            // we can put the value in the deployment config
                            deployParamVals[deployParam.deployParamId] = getInitialDeployParamValue(deployParam);
                            neededDeployParams[deployParam.deployParamId] = deployParam;
                        } else if (deployParamVals[deployParam.deployParamId].kind !== deployParam.deployParamKind) {
                            // we must put the value on the LDV config
                            newLdv.config.deployParamVals[deployParam.deployParamId] = getInitialDeployParamValue(deployParam);
                        }
                    }

                    return newLdv;
                }
            });

            // remove unneeded deploy params
            for (const [ deployParamId, deployParam ] of Object.entries(deployParamVals)) {
                if (!neededDeployParams[deployParamId] || deployParam.kind !== neededDeployParams[deployParamId].deployParamKind) {
                    delete deployParamVals[deployParamId];
                }
            }
            accessor.setPageEntity({
                type: 'appletDeployment',
                entity: {
                    ...deploymentEntity,
                    linkedDesignVersions,
                    config: {
                        ...deploymentEntity.config,
                        deployParamVals,
                    },
                },
            });
        }
    },
});

const getters = getterTree({}, {
    parameters: () => {
        return async function parameters (): Promise<BaseDeploymentParameter[]> {
            const parametersFromConfig: BaseDeploymentParameter[] = [];
            const deploymentConfig = accessor.entityAsType('appletDeployment');
            if (!deploymentConfig) {
                return parametersFromConfig;
            }

            // Cannot use the TRPC query hook to get the deployment config becuase the linked designs are changing at runtime
            const designVersions = await apiTrpcHttpClient.applet.designVersion.getVersionsFromIds.query({
                designVerIds: deploymentConfig.linkedDesignVersions.map(ldv => ldv.designVersion.id),
            });
            if (!designVersions) {
                return parametersFromConfig;
            }

            for (const designVersion of designVersions) {
                if (designVersion.config.sparkplugDeviceMetrics) {
                    parametersFromConfig.push({
                        name: 'sparkplugParameter',
                        type: 'sparkplug',
                        sparkplugMetrics: designVersion.config.sparkplugDeviceMetrics.map(metric => ({
                            variableName: metric.name,
                            isWritable: metric.permissions.write,
                        })),
                    } as SparkplugDeploymentParameter);
                }
            }
            return parametersFromConfig;
        };
    },
});

export const appConfigStore = {
    namespaced: true,
    state: {},
    getters,
    actions,
};

const storeType = getStoreType(appConfigStore);
export type AppConfigStore = typeof storeType;

export default appConfigStore;
