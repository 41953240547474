import { ArgoUdfColumnDataTypeEnum } from '@redviking/argonaut-util/types/db';
import { z } from 'zod';
import { locationSchema } from '../../../location.deployParam';
import { varNameSchema } from '../../var.zod';
import { varConditionSchema } from './conditional.zod';
import { varOrConstSchema, varpConfigBase } from './var-provider.zod';

export const snCfgDirectSchema = z.object({
    type: z.literal('direct'),
    serialNumber: varOrConstSchema(z.string()),
});

export const snCfgFormattedSchema = z.object({
    type: z.literal('formatter'),
    inputs: z.record(z.string(), varOrConstSchema(z.string())),
    tz: varOrConstSchema(z.union([ z.string(), z.null() ])),
    outputVar: z.string(),
});

export const createMaterialUdfValuesSchema = z.record(z.string(), z.object({
    required: z.boolean(),
    name: z.string(),
    type: z.nativeEnum(ArgoUdfColumnDataTypeEnum),
    val: varOrConstSchema(z.union([ z.string(), z.number() ])),
}));

export const materialModelSchema = z.object({
    /** keyed by attribute id, value is formatted name for UI */
    attributes: z.record(z.string(), z.string()),
    materialModelId: z.string(),
    udfs: createMaterialUdfValuesSchema,
    snCfg: z.discriminatedUnion('type', [ snCfgDirectSchema, snCfgFormattedSchema ]),
    /** hidden control for now */
    ts: varOrConstSchema(),
    conditional: varConditionSchema.nullable(),
    labelPrint: z.boolean(),
});

export const createMaterialProviderAttrs = z.object({
    location: z.preprocess(value => {
        // previously, this was a string that indicated if the location should come from the mes-app config location / app device link provided location
        return typeof value === 'string' ? { deployParamId: 'Location', deployParamKind: 'location' } : value;
    }, locationSchema),
    materialIdVar: z.string(),
    /**
     * When this condition is satisfied, a material will be created (depending on the model conditions)
     */
    conditional: varConditionSchema,
    /**
     * If the material to create already exists (serial number conflict) then simply get the material's info instead of trying to create a new one
     * @default true
     */
    allowExistingMaterial: z.boolean(),
    /**
     * Each of the possible material models that can be created by this provider
     */
    materialModels: z.array(materialModelSchema),
});

export const createMaterialProviderSchema = varpConfigBase.extend({
    type: z.literal('createMaterial'),
    inputs: z.array(varNameSchema),
    attrs: createMaterialProviderAttrs,
});
