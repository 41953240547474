import { z } from 'zod';
import { varNameSchema } from '../../var.zod';
import { varConditionSchema } from './conditional.zod';
import { localVarCfgSchema } from './local.zod';
import { varpConfigBase } from './var-provider.zod';

const customExecutionSchema = z.object({
    type: z.literal('custom'),
    condition: varConditionSchema,
});

const defaultExecutionSchema = z.object({
    type: z.literal('default'),
});

export const scriptProviderAttrsSchema = z.object({
    /**
     * applet variables that are referenced by the script.
     * this can include outputs of the varp itself, and custom variables in another varp that are read or written to.
     */
    includedVars: z.array(z.object({
        /** whether the referenced variable can be tracked (is an input, not an output) */
        tracked: z.boolean(),
        name: z.string(),
    })),
    outputVars: z.array(localVarCfgSchema),
    scriptId: z.string(),
    executeCfg: z.discriminatedUnion('type', [
        defaultExecutionSchema,
        customExecutionSchema,
    ]),
});

export const scriptProviderSchema = varpConfigBase.extend({
    type: z.literal('script'),
    attrs: scriptProviderAttrsSchema,
    inputs: z.array(varNameSchema),
});
