import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

import z from 'zod';
import { varOrConstSchema } from '../var-providers/var-provider.zod';
import { varConditionSchema } from '../var-providers/conditional.zod';

const pageRuleBase = z.object({
    action: z.string(),
    condition: varConditionSchema,
});

/**
 * advances to the next page that is not disabled
 *
 * - if all pages are disabled do not switch and change page state to `error`
 * - if the page that is ready to be advanced is the last page advance to the first page if it is not disabled. If it is find the next page that isn't
 */
const advanceAutoPageRuleSchema = pageRuleBase.extend({
    action: z.literal('advance'),
});

/**
 * advances to the page with the given cell name.
 *
 * - if the target page is disabled do not switch and change the pages state to `warn`
 */
const advanceToPageRuleSchema = pageRuleBase.extend({
    action: z.literal('advanceTo'),
    pageNumber: z.number(),
});

/**
 * Disables the page, prevents the page from being activated.
 *
 * - if any of the rules are true, disable the page
 * - if a currently visible page is disabled, nothing happens. Only future attempts to activate a disabled page will be rejected.
 */
const disablePageRuleSchema = pageRuleBase.extend({
    action: z.literal('disable'),
});

const pageDisplayRuleEnumSchema = z.enum([ 'success', 'error', 'warning', 'info', 'default' ]);

/**
 * applies an appearance to the page in the list.
 *
 * evaluated whenever, and the first state rule to match is applied.
 */
const pageDisplayRuleSchema = pageRuleBase.extend({
    action: z.literal('display'),
    status: pageDisplayRuleEnumSchema,
});

/**
 * Aggregation of all of the rules that attempt to advance the page
 *
 * The first condition that is true will be applied
 */
const advancePageRuleSchema = z.discriminatedUnion('action', [
    advanceAutoPageRuleSchema,
    advanceToPageRuleSchema,
]);
export type AdvancePageRule = z.infer<typeof advancePageRuleSchema>;

export const pageCfgSchema = z.object({
    rules: z.object({
        advanceRules: z.array(advancePageRuleSchema),
        disableRules: z.array(disablePageRuleSchema),
        displayRules: z.array(pageDisplayRuleSchema),
    }),
    pageName: varOrConstSchema(z.string()),
    hideWhenDisabled: z.boolean(),
    id: z.string(),
});

export const pagedResetConditionSchema = z.object({
    /**
     * Condition on when to reset the page sequence
     */
    condition: varConditionSchema,
    /**
     * Array of vars to clear the value of during a sequence reset.
     *
     * Ending result of vars in this array will be `{status: 'ok', val: null}`
     */
    vars: z.array(z.string()),
});

export const pagedAttrsSchemaDef = cellCfgAttrsBaseSchema.extend({
    transition: z.enum([ 'slide-x', 'slide-y' ]),
    /** the variable to output the current page */
    currentPageVar: z.string(),
    resetConditions: z.array(pagedResetConditionSchema),
    btnSize: z.enum([ 'small', 'medium', 'large', 'x-large', 'hidden' ]).default('medium'),
    pages: z.array(pageCfgSchema),
});

export const pagedCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('paged'),
    attrs: pagedAttrsSchemaDef,
    modifiers: z.record(z.enum([
        'transition',
        'btnSize',
        'background',
        'borderColor',
    ]), z.string().optional()).optional(),
});
