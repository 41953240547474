import { z } from 'zod';
import { optionalVarNameSchema, varNameSchema } from '../../var.zod';

export function varOrConstSchema <ValidResult extends string | number | null = string | number | null> (validResultSchema: z.ZodType<ValidResult> = z.union([ z.string(), z.number(), z.null() ]) as unknown as z.ZodType<ValidResult>) {
    return z.discriminatedUnion('type', [
        z.object({
            type: z.literal('var'),
            var: varNameSchema,
        }),
        z.object({
            type: z.literal('const'),
            val: validResultSchema,
        }),
    ]);
}

export const varpConfigBase = z.object({
    type: z.string(),
    /**
     * the name of the container for the federated module that provides this var provider.
     * the app should maintain a mapping between container names and remote entry configurations.
     * We could have used the `type` to map to the container, but then we wouldn't be able to have multiple versions of a var provider.
     *
     * @default 'builtin'
     */
    source: z.string().default('builtin').optional(),
    label: z.string().optional(),
    /** unique id for easy store updates */
    id: z.string(),
    /** variable names to act as inputs to this var provider */
    inputs: z.array(optionalVarNameSchema).default([]).optional(),
    /** variable names to act as outputs of this var provider */
    outputs: z.array(optionalVarNameSchema),
    attrs: z.record(z.unknown()),
});
