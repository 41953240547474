import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

import z from 'zod';

export const mediaCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('media'),
    attrs: cellCfgAttrsBaseSchema.extend({
        items: z.array(z.object({
            url: z.string(),
            /** seconds */
            timeout: z.number().optional(),
            caption: z.string().optional(),
            /** name of the variable that will set whether the image is shown or not */
            variable: z.string().optional(),
        })),
    }),
});
