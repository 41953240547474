/* eslint-disable complexity */
import {
    AddUserDocument,
    GetUserDocument,
    UpdateUserDocument,
} from '@redviking/argonaut-core-ui/types/db';
import { userToString } from '@redviking/argonaut-core-ui/src/util';
import { gqlClient } from '@redviking/argonaut-core-ui/src/util/gql-client';
import { accessor } from '../store';
import { Route } from 'vue-router';
import { RouterViewSlider } from '../components/RouterViewSlider';
import { RouteEntityDetail } from '@redviking/argonaut-core-ui/src/components/EntityDetail';
import { messages } from 'src/i18n/i18n';
import { isAdmin, validateUserSelectAcl, validateUserUpdateAcl } from './user.validations';
import {
    type EntityModifyParameters,
    type ExtendedEntityParameters,
    entityDetailRoute,
} from '@redviking/argonaut-core-ui/src/components/EntityDetail/entity-route-util';
import { Notify } from '@redviking/argonaut-core-ui/src/notifications';
import { entityPageBannerTitle } from 'src/util/formatting';
import { v4 as uuidv4 } from 'uuid';
import { getListChanges } from 'src/util/composables/watch-list-changes';
import { EntityTypeMap } from 'types';
import { userFragmentToEntityType } from './user.entity';
import { apiTrpcHttpClient } from 'src/util/api-trpc';

export default [
    {
        path: '/users',
        name: 'users',
        redirect: { name: 'userList' },
        component: RouterViewSlider,
        meta: {
            navigationGuard: () => validateUserSelectAcl(),
            navbarTitle: messages.users.titles.nav,
            navbarIcon: 'mdi-account',
            transition: 'horizontal',
        },
        children: [
            {
                name: 'userList',
                path: '',
                component: () => import(/* webpackChunkName: "users" */ './UsersView.vue'),
                meta: {
                    pageBannerTitle: messages.users.titles.pageBannerTitle,
                    pageBannerSubtitle: messages.users.titles.pageBannerSubtitle,
                },
            },
            entityDetailRoute<'user'>({
                path: ':userId',
                name: 'userMaintenance',
                component: RouteEntityDetail,
                redirect: to => ({ name: 'userSettings', params: to.params }),
                meta: {
                    returnRoute: (route: Route) => ({ name: 'userList', query: route.query }),
                    canEdit: () => (accessor.entity?.entity as EntityTypeMap['user']).username !== 'applet' && validateUserUpdateAcl(),
                    entityType: 'user',
                    getEntity: async (to) => {
                        if (to.query.mode === 'create') {
                            return {
                                entity: {
                                    description: '',
                                    enabled: true,
                                    firstName: '',
                                    id: to.params.userId,
                                    lastName: '',
                                    userContexts: isAdmin()
                                        ? []
                                        : [ { id: uuidv4(), context_id: accessor.auth.contextId } ],
                                    userRoles: [],
                                    username: '',
                                    localAuth: {
                                        id: '',
                                        email: '',
                                        password: '',
                                        currentPassword: '',
                                    },
                                    badgeAuth: {
                                        id: '',
                                        badge: '',
                                    },
                                    userAccessTokens: [],
                                    createdBy: null,
                                    updatedBy: null,
                                },
                            };
                        } else {
                            const { user: [ user ] } = await gqlClient.request({
                                document: GetUserDocument,
                                variables: {
                                    queryFilter: {
                                        id: {
                                            _eq: to.params.userId,
                                        },
                                    },
                                },
                            });

                            if (!user) {
                                throw new Error('Could not find user');
                            }

                            return {
                                entity: userFragmentToEntityType(user),
                            };
                        }
                    },
                    createEntity: async (payload: EntityModifyParameters<'user'>) => {
                        const { entity: user } = payload;
                        if (!user.username || user.username.length <= 0) {
                            const error = new Error('User missing "username" property');
                            Notify.error(error.message, error);
                            return;
                        }

                        try {
                            await gqlClient.request({
                                document: AddUserDocument,
                                variables: {
                                    user: {
                                        id: user.id,
                                        username: user.username,
                                        first_name: user.firstName,
                                        last_name: user.lastName,
                                        description: user.description,
                                        enabled: user.enabled,
                                        user_contexts: {
                                            data: user.userContexts.map(l => {
                                                return isAdmin()
                                                    ? {
                                                        id: l.id,
                                                        context_id: l.context_id,
                                                    }
                                                    : {
                                                        id: l.id,
                                                    };
                                            }),
                                        },
                                        user_roles: {
                                            data: user.userRoles.map(l => {
                                                return isAdmin()
                                                    ? {
                                                        id: l.id,
                                                        context_id: l.context_id,
                                                        role_id: l.role_id,
                                                    }
                                                    : {
                                                        id: l.id,
                                                        role_id: l.role_id,
                                                    };
                                            }),
                                        },
                                    },
                                },
                            });

                            if (`${user.localAuth?.email || ''}`.length > 0) {
                                await apiTrpcHttpClient.auth.provider.local.create.mutate({
                                    userId: user.id,
                                    email: `${user.localAuth?.email || ''}`.toLowerCase().trim(),
                                    password: user.localAuth?.password || '',
                                });
                            }

                            if (`${user.badgeAuth?.badge || ''}`.length > 0) {
                                await apiTrpcHttpClient.auth.provider.badge.create.mutate({
                                    userId: user.id,
                                    badge: user.badgeAuth?.badge || '',
                                });
                            }

                            Notify.win(messages.users.user.saved);

                            return {
                                status: 'success',
                                route: { name: 'userList' },
                                // TODO:Something still isn't right in the newEntity return... Badge/Email don't seem to be coming back
                                // newEntity: userFragmentToEntityType(userInsertMutation!),
                            };

                        } catch (err) {
                            Notify.error(err);
                            throw err;
                        }
                    },
                    // eslint-disable-next-line complexity
                    saveEntity: async (payload: ExtendedEntityParameters<'user'>) => {

                        const {
                            entity: user,
                            oldEntity: originalUser,
                        } = payload;

                        if (!user.username || user.username.length <= 0) {
                            const error = new Error('Username required');
                            Notify.error(error.message, error);
                            return;
                        }

                        const userContextPlc = getListChanges(user.userContexts, originalUser?.userContexts || [], { deep: true, format: 'plc' });
                        const userRolePlc = getListChanges(user.userRoles, originalUser?.userRoles || [], { deep: true, format: 'plc' });
                        const userAccessTokens = getListChanges(user.userAccessTokens || [], originalUser?.userAccessTokens || [], { deep: true, format: 'plc' });

                        const { updateResults: updatedUser } = await gqlClient.request({
                            document: UpdateUserDocument,
                            variables: {
                                isAdmin: isAdmin(),
                                userId: user.id,
                                user: {
                                    description: user.description,
                                    enabled: user.enabled,
                                    first_name: user.firstName,
                                    last_name: user.lastName,
                                    username: user.username,
                                },
                                insertUserContexts: userContextPlc.inserts.map(uc => ({
                                    id: uc.id,
                                    context_id: uc.context_id,
                                    user_id: user.id,
                                })),
                                deleteUserContextsBoolExp: {
                                    id: { _in: userContextPlc.deletes.map(uc => uc.id) },
                                },
                                insertUserRoles: userRolePlc.inserts.map(i => {
                                    return isAdmin()
                                        ? {
                                            id: i.id,
                                            user_id: user.id,
                                            role_id: i.role_id,
                                            context_id: i.context_id,
                                        }
                                        : {
                                            id: i.id,
                                            user_id: user.id,
                                            role_id: i.role_id,
                                        };
                                }),
                                deleteUserRoleBoolExp: {
                                    id: { _in: userRolePlc.deletes.map(ur => ur.id) },
                                },
                                deleteUserAccessTokenKeysBoolExp: {
                                    id: { _in: userAccessTokens.deletes.map(ka => ka.id) },
                                },
                            },
                        });

                        // Updating a user's password is done async in the user settings page
                        if (!originalUser?.localAuth?.id && `${user.localAuth?.email || ''}`.length > 0) {
                            await apiTrpcHttpClient.auth.provider.local.create.mutate({
                                userId: user.id,
                                email: `${user.localAuth?.email || ''}`.toLowerCase().trim(),
                                password: user.localAuth?.password || '',
                            });
                        }

                        if (!originalUser?.badgeAuth?.id && `${user.badgeAuth?.badge || ''}`.length > 0) {
                            await apiTrpcHttpClient.auth.provider.badge.create.mutate({
                                userId: user.id,
                                badge: user.badgeAuth?.badge || '',
                            });
                        } else if (originalUser?.badgeAuth?.id && user.badgeAuth?.badge && originalUser.badgeAuth?.badge !== user.badgeAuth?.badge && user.badgeAuth?.id) {
                            await apiTrpcHttpClient.auth.provider.badge.update.mutate({
                                authProviderBadgeId: user.badgeAuth?.id || '',
                                badge: user.badgeAuth?.badge || '',
                            });
                        }

                        Notify.win('User Saved!');

                        return {
                            status: 'success',
                            newEntity: updatedUser,
                        };

                    },
                    pageBannerTitle: () => entityPageBannerTitle('user', userToString(accessor.entityAsType('user'))),
                },
                children: [
                    {
                        path: 'settings',
                        name: 'userSettings',
                        component: () => import(/* webpackChunkName: "users" */ './UserSettingsView.vue'), // a component for the settings page content
                        meta: {
                            tab: { // the presence of this prop indicates this is a tab
                                label: 'Settings',
                                icon: 'mdi-cog',
                            },
                        },
                    },
                ],
            }),
        ],
    },
];
