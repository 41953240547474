import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';
import z from 'zod';
import { macroTypeEnum } from '../macros/base-macro.zod';

const macroTargetCellAttrsSchema = cellCfgAttrsBaseSchema.extend({
    macroId: z.string(),
    macroType: macroTypeEnum,
    targetName: z.string(),
});

/**
 * defines a cell that is configured by the output of a macro
 */
export const macroTargetCellSchema = cellCfgBaseSchema.extend({
    type: z.literal('macroTarget'),
    attrs: macroTargetCellAttrsSchema,
});
