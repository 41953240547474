import type { Route } from 'vue-router';
import type { Deployment, LinkedDesignVersionConfig } from '@redviking/argonaut-util/src/mes/appletDeployment.zod';
import { apiTrpcHttpClient } from '@redviking/argonaut-core-ui/src/util/api-trpc';
import { Notify } from '@redviking/argonaut-core-ui/src/notifications';
import { accessor } from '@redviking/argonaut-core-ui/src/store';
import { applySparkplugRequirements } from './util/sparkplug-formatter';
import { DeployParam, getDeployParams } from '@redviking/argonaut-util/src/mes/deployParam.zod';
import { appletDesignSchema } from '@redviking/argonaut-util/types/mes/applet-designs/applet-design-config.zod';
import { Latest, latestAppletDesignVersionNumber } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';

export type AppletDeploymentVersionEntity = Omit<Deployment, 'linkedDesignVersions' | 'contextId' | 'contextName'> & {
    linkedDesignVersions: {
        config: LinkedDesignVersionConfig;
        designVersion: {
            id: string;
            name: string;
            designId: string;
            versionNumber: number;
            screens: Latest.Screen.Config[];
        };
        /** these are dynamically aggregated by `getDeployParams` from wherever deployment params might be defined in the design version config */
        deployParams?: (DeployParam & { deployParamId: string })[];
    }[];
};

export async function getAppletDeploymentVersion (to: Route) {
    if (to.query.mode === 'create') {
        const query = to.query as Record<string, string | undefined>;
        const deploymentName = 'New Deployment';
        const deploymentVersion = 1;
        const newEntity: AppletDeploymentVersionEntity = {
            id: to.params.appletDeploymentId,
            name: deploymentName,
            notes: '',
            runnerId: query.appletRunnerId || '',
            linkedDesignVersions: [],
            config: {
                deploymentSparkplugNode: {
                    version: 'spBv1.0',
                    groupId: `${applySparkplugRequirements(accessor.auth.currentContext?.name || 'unknown-context-name')}-${applySparkplugRequirements(query.appletRunnerId || '')}`,
                    nodeId: applySparkplugRequirements(deploymentName),
                    usingSparkplug: true,
                },
                deployParamVals: {},
                defaults: {
                    designVersionId: '',
                    screen: '',
                },
            },
            version: deploymentVersion,
        };
        return { entity: newEntity, originalEntity: null };
    }

    const deployment = await apiTrpcHttpClient.applet.deployment.getById.query({
        id: to.params.appletDeploymentId,
    });

    if (!deployment) {
        const err = new Error('Not found');
        Notify.error(err);
        throw err;
    }

    const errors: Error[] = []; // We are collecting errors but are not doing anything with them?
    const originalEntity: AppletDeploymentVersionEntity = {
        config: {
            ...deployment.config,
            defaults: {
                designVersionId: deployment.config.defaults?.designVersionId || '',
                screen: deployment.config.defaults?.screen || '',
            },
        },
        id: deployment.id,
        name: deployment.name,
        notes: deployment.notes,
        linkedDesignVersions: deployment.linkedDesignVersions.map(ldv => {

            let deployParams: ReturnType<typeof getDeployParams> = [];
            try {
                deployParams = getDeployParams(ldv.designVersion.config, appletDesignSchema) || {};
            } catch (err) {
                errors.push(err);
            }
            return {
                config: ldv.config,
                designVersion: {
                    id: ldv.designVersion.id,
                    name: ldv.designVersion.design.name,
                    designId: ldv.designVersion.design.id,
                    versionNumber: ldv.designVersion.number,
                    screens: ldv.designVersion.config.schemaVersion === latestAppletDesignVersionNumber ? ldv.designVersion.config.screens : [],
                },
                deployParams,
            };
        }),
        runnerId: deployment.runnerId,
        version: deployment.version,
    };
    const entity = { ...originalEntity };

    return { entity, originalEntity };
}

export async function saveAppletDeploymentVersion (entity: AppletDeploymentVersionEntity): Promise<AppletDeploymentVersionEntity> {
    const deployment = await apiTrpcHttpClient.applet.deployment.upsert.mutate({
        deployment: {
            contextName: accessor.auth.currentContext?.name || '',
            currentVersion: entity.version,
            linkedDesignVersions: entity.linkedDesignVersions.map(ldv => ({
                designVersionId: ldv.designVersion.id,
                config: ldv.config,
            })),
            name: entity.name,
            notes: entity.notes,
            runnerId: entity.runnerId,
            id: entity.id,
            config: {
                deploymentSparkplugNode: {
                    ...entity.config.deploymentSparkplugNode,
                    nodeId: applySparkplugRequirements(entity.name),
                },
                deployParamVals: entity.config.deployParamVals,
                defaults: {
                    designVersionId: entity.config.defaults?.designVersionId || '',
                    screen: entity.config.defaults?.screen || '',
                },
            },
        },
    });

    return {
        ...deployment,
        linkedDesignVersions: deployment.linkedDesignVersions.map(ldv => ({
            config: ldv.config,
            designVersion: {
                id: ldv.designVersion.id,
                name: ldv.designVersion.design.name,
                designId: ldv.designVersion.design.id,
                versionNumber: ldv.designVersion.number,
                screens: ldv.designVersion.config.schemaVersion === latestAppletDesignVersionNumber ? ldv.designVersion.config.screens : [],
            },
            deployParams: getDeployParams(ldv.designVersion.config, appletDesignSchema),
        })),
    };
}
