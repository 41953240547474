
import Vue, {
    VNode,
    defineComponent,
} from 'vue';
// @ts-expect-error avoiding normal import because it'll include incorrect type definitions, which expect an i18n instance on the root component.
import VueI18n from 'vue-i18n/dist/vue-i18n.esm.js';

// we need to create a vue-i18n instance so the Interpolation component can use its formatter.
// creating it here instead of at the root component prevents mis-use elsewhere
Vue.use(VueI18n); // ensures i18n plugin is registered before an instance is created
export const interpolationI18n = new VueI18n();

export default defineComponent({
    functional: true,
    props: {
        msg: { type: String, required: true },
        tag: { type: String, required: false, default: 'span' },
        className: { type: String, required: false, default: '' },
    },
    render (createElement, ctx) {
        const slots: Record<string, VNode> = ctx.slots();
        return createElement(ctx.props.tag, {
            class: ctx.props.className,
        }, interpolationI18n.formatter.interpolate(ctx.props.msg, slots, 'argo-interpolate-i18n'));
    },
});
