import { z } from 'zod';
import { cellCfgAttrsBaseSchema, cellCfgBaseSchema } from './cell-base.zod';

export const pageCellSchema = cellCfgBaseSchema.extend({
    attrs: cellCfgAttrsBaseSchema.extend({
        pageMacroId: z.string(),
    }),
    order: z.number().optional(),
    type: z.literal('page'),
});

