
import {
    computed,
    defineComponent,
    onBeforeUnmount,
    ref,
    watch,
} from 'vue';
import { accessor } from 'src/store';
import * as Diff from 'diff';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';

type CharDifferencePayload = {
    edit: string;
    endLine: number;
    original: string;
    startLine: number;
    mode: 'added' | 'removed';
};

const addNewLines = (scriptValue: string, start: number, end: number): string => {
    // splits the code by newline, splices in newline characters to make up the difference in line count, then joins the code back together
    // subtracting 1 from the line numbers to help line up the code with other editors
    const prevValue = scriptValue.split('\n');
    const adjustedStartLineNumber = start - 1;
    const adjustedEndLineNumber = end - 1;
    return [
        ...prevValue.slice(0, adjustedStartLineNumber),
        ...Array(adjustedEndLineNumber - adjustedStartLineNumber + 1).fill('\n'),
        ...prevValue.slice(adjustedStartLineNumber),
    ].reduce((acc, curr) => curr === '\n'
        ? `${acc}${curr}`
        : `${acc}${curr}\n`, '');
};


// This function is used to get the character difference between two strings. It is intended to be used to compare two results from the Diff.diffLines in order to account for lines that have both additiond and removals
const getCharDifference = (payload: CharDifferencePayload): monaco.editor.IModelDeltaDecoration[] => {
    const charDecorations: monaco.editor.IModelDeltaDecoration[] = [];
    // filtering out all removals. This makes sense under the school of thought that a removal cannot occur on a change that hasn't happened yet.
    // NOTE: This function treats both modes (added and removed) as if the original string is the one that is being edited. This is because the original string is the one that is being compared to the edited string.
    const diffChars = Diff.diffChars(payload.original, payload.edit).filter(diffCharInstance => !diffCharInstance.removed);
    let charCount = 0;
    for (const diffCharInstance of diffChars) {
        if (!diffCharInstance.count) {
            throw new Error('Diff char instance has no count?');
        }
        const startCharNumber = charCount + 1;
        const endCharNumber = charCount + diffCharInstance.count + 1;
        if (diffCharInstance.added) {
            const charDecoration: monaco.editor.IModelDeltaDecoration = {
                range: new monaco.Range(payload.startLine, startCharNumber, payload.endLine, endCharNumber),
                options: {
                    inlineClassName: payload.mode === 'removed'
                        ? 'removed-change-char-for-json-view'
                        : 'added-change-char-for-json-view',
                },
            };
            charDecorations.push(charDecoration);
        }
        charCount += diffCharInstance.count;
    }
    return charDecorations;
};

const attachEditor = (element: HTMLElement, value: string): monaco.editor.IStandaloneCodeEditor => {
    return monaco.editor.create(element, {
        value,
        readOnly: true,
        language: 'json',
        automaticLayout: true,
        showDeprecated: false,
        quickSuggestions: false,
        fixedOverflowWidgets: true,
        minimap: {
            enabled: false,
        },
    });
};

export const JSONDiff = defineComponent({
    name: 'JSONDiff',
    setup () {

        // monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        //     validate: false,
        // });

        const shouldSyncScroll = ref(true);

        const editedEntityData = ref(accessor.entity?.entity || {});
        const originalEntityData = ref(accessor.originalEntity?.entity || {});

        const editedEntityDataString = JSON.stringify(editedEntityData.value, null, 2);

        const originalEntityDataString = JSON.stringify(originalEntityData.value, null, 2);

        const diffData = Diff.diffLines(originalEntityDataString, editedEntityDataString);
        const diffEntityDataString = diffData.reduce((acc, curr) => acc + curr.value, '');

        const diffEntityElement = ref<HTMLElement | null>(null);
        const editedEntityElement = ref<HTMLElement | null>(null);
        const originalEntityElement = ref<HTMLElement | null>(null);

        const diffEntityEditor = ref<monaco.editor.IStandaloneCodeEditor | undefined>(undefined);
        const editedEntityEditor = ref<monaco.editor.IStandaloneCodeEditor | undefined>(undefined);
        const originalEntityEditor = ref<monaco.editor.IStandaloneCodeEditor | undefined>(undefined);

        watch(computed(() => originalEntityElement.value), newOriginalEntityElement => {
            if (newOriginalEntityElement === null) {
                return;
            }
            originalEntityEditor.value = attachEditor(newOriginalEntityElement, originalEntityDataString);
            const originalEditor = originalEntityEditor.value;
            const decorations: monaco.editor.IModelDeltaDecoration[] = [];
            let lineCount = 0;
            diffData.forEach(diffInstance => {
                if (!diffInstance.count) {
                    throw new Error('Diff instance has no count?');
                }
                const startLineNumber = lineCount + 1;
                const endLineNumber = lineCount + diffInstance.count;
                if (diffInstance.added) {
                    originalEditor.setValue(addNewLines(originalEditor.getValue(), startLineNumber, endLineNumber));
                    const decoration: monaco.editor.IModelDeltaDecoration = {
                        range: new monaco.Range(startLineNumber, 1, endLineNumber, 1),
                        options: {
                            isWholeLine: true,
                        },
                    };
                    if (startLineNumber === endLineNumber) {
                        decoration.options.className = 'filler-line-for-json-view';
                    } else {
                        decoration.options.blockClassName = 'filler-line-for-json-view';
                    }
                    decorations.push(decoration);
                }
                lineCount += diffInstance.count;
            });
            originalEntityEditor.value.createDecorationsCollection(decorations);
            originalEntityEditor.value.onDidScrollChange(e => {
                if (!shouldSyncScroll.value) {
                    return;
                }
                editedEntityEditor.value?.setScrollTop(e.scrollTop);
                diffEntityEditor.value?.setScrollTop(e.scrollTop);
            });
        });

        watch(computed(() => editedEntityElement.value), newEditedEntityElement => {
            if (newEditedEntityElement === null) {
                return;
            }
            editedEntityEditor.value = attachEditor(newEditedEntityElement, editedEntityDataString);
            const decorations: monaco.editor.IModelDeltaDecoration[] = [];
            let lineCount = 0;
            const editedEditor = editedEntityEditor.value;
            diffData.forEach(diffInstance => {
                if (!diffInstance.count) {
                    throw new Error('Diff instance has no count?');
                }
                const startLineNumber = lineCount + 1;
                const endLineNumber = lineCount + diffInstance.count;
                if (diffInstance.removed) {
                    editedEditor.setValue(addNewLines(editedEditor.getValue(), startLineNumber, endLineNumber));
                    const decoration: monaco.editor.IModelDeltaDecoration = {
                        range: new monaco.Range(startLineNumber, 1, endLineNumber, 1),
                        options: {
                            isWholeLine: true,
                        },
                    };
                    if (startLineNumber === endLineNumber) {
                        decoration.options.className = 'filler-line-for-json-view';
                    } else {
                        decoration.options.blockClassName = 'filler-line-for-json-view';
                    }
                    decorations.push(decoration);
                }
                lineCount += diffInstance.count;
            });
            editedEntityEditor.value.createDecorationsCollection(decorations);
            editedEntityEditor.value.onDidScrollChange(e => {
                if (!shouldSyncScroll.value) {
                    return;
                }
                diffEntityEditor.value?.setScrollTop(e.scrollTop);
                originalEntityEditor.value?.setScrollTop(e.scrollTop);
            });
        });

        watch(computed(() => diffEntityElement.value), newDiffEntityElement => {
            if (newDiffEntityElement === null) {
                return;
            }
            diffEntityEditor.value = attachEditor(newDiffEntityElement, diffEntityDataString);
            const decorations: monaco.editor.IModelDeltaDecoration[] = [];
            let lineCount = 0;
            let previousDiffInstance: Diff.Change | null = null;
            let nextDiffInstance: Diff.Change | null = null;
            diffData.forEach((diffInstance, idx) => {
                if (!diffInstance.count) {
                    throw new Error('Diff instance has no count?');
                }

                nextDiffInstance = diffData[idx + 1] || null;
                const startLineNumber = lineCount + 1;
                const endLineNumber = lineCount + diffInstance.count;

                const decoration: monaco.editor.IModelDeltaDecoration = {
                    range: new monaco.Range(startLineNumber, 1, endLineNumber, 1),
                    options: {
                        isWholeLine: true,
                    },
                };
                if (diffInstance.added) {
                    if (previousDiffInstance && previousDiffInstance.removed) {
                        decorations.push(...getCharDifference({
                            mode: 'added',
                            endLine: endLineNumber,
                            edit: diffInstance.value,
                            startLine: startLineNumber,
                            original: previousDiffInstance.value,
                        }));
                    }
                    decoration.options.blockClassName = 'added-change-for-json-view';
                    decorations.push(decoration);
                } else if (diffInstance.removed) {
                    if (nextDiffInstance && nextDiffInstance.added) {
                        decorations.push(...getCharDifference({
                            mode: 'removed',
                            endLine: endLineNumber,
                            edit: diffInstance.value,
                            startLine: startLineNumber,
                            original: nextDiffInstance.value,
                        }));
                    }
                    decoration.options.blockClassName = 'removed-change-for-json-view';
                    decorations.push(decoration);
                }
                lineCount += diffInstance.count;
                previousDiffInstance = diffInstance;
            });
            diffEntityEditor.value.createDecorationsCollection(decorations);
            diffEntityEditor.value.onDidScrollChange(e => {
                if (!shouldSyncScroll.value) {
                    return;
                }
                editedEntityEditor.value?.setScrollTop(e.scrollTop);
                originalEntityEditor.value?.setScrollTop(e.scrollTop);
            });
        });

        const showingDiffEditor = ref(true);
        const showingEditedEditor = ref(false);
        const showingOriginalEditor = ref(true);

        onBeforeUnmount(() => {
            diffEntityEditor.value?.dispose();
            editedEntityEditor.value?.dispose();
            originalEntityEditor.value?.dispose();

            diffEntityEditor.value = undefined;
            editedEntityEditor.value = undefined;
            originalEntityEditor.value = undefined;
        });

        return {
            shouldSyncScroll,
            showingDiffEditor,
            diffEntityElement,
            showingEditedEditor,
            editedEntityElement,
            showingOriginalEditor,
            originalEntityElement,
            startingButtonValues: ref([ 0, 2 ]),
            cols: computed(() => 12 / (Number(showingDiffEditor.value) + Number(showingEditedEditor.value) + Number(showingOriginalEditor.value))),
        };
    },
});
export default JSONDiff;

