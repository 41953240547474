import { z } from 'zod';
import { locationSchema } from '../../../location.deployParam';
import { varpConfigBase } from './var-provider.zod';

export const defaultMetaCalendarEventTopicSchema = z.enum([ 'timeStart', 'timeEnd', 'name' ]);
export const defaultCurrTimeCalendarEventTopicSchema = z.enum([ 'elapsedProductive', 'remainingProductive', 'elapsedSeconds', 'remainingSeconds' ]);

export const defaultNextTimeCalendarEventTopicSchema = z.enum([ 'secondsUntil' ]);

export const calendarEventOccuranceSchema = z.enum([ 'currentShift', 'nextShift', 'currentEvent', 'nextEvent' ]);

export const calendarEventSchema = z.object({
    varName: z.string(),
    defaultDataValue: z.union([ z.string(), z.number() ]),
    type: z.union([ z.literal('default'), z.literal('udf') ]),
    occurance: calendarEventOccuranceSchema,
    valueDataType: z.enum([ 'text', 'number' ]),
});

const udfCalendarEventSchema = calendarEventSchema.extend({
    dataType: z.literal('meta'),
    udfColId: z.string(),
    type: z.literal('udf'),
});

export const calendarProviderSchema = varpConfigBase.extend({
    type: z.literal('calendar'),
    attrs: z.object({
        location: locationSchema,
        varPrefix: z.string(),
        /**
         * List of objects that map unknown calendar event properties to MES variables
         */
        udfCalendarEvents: z.array(udfCalendarEventSchema),
    }),
});
