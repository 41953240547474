
import {
    PropType,
    computed,
    defineComponent, ref, watch,
} from 'vue';
import _isEqual from 'lodash.isequal';
import EntityDetail from './EntityDetail.view.vue';
import type { Component } from 'vue-router/types/router';
import { Location, Route, RouteConfig } from 'vue-router';
import { useDisabled, useEditing, useEntityMode } from './useEntityDetail';
import { EntityDetailMode } from './mode';
import { useRoute } from 'src/util/composables';
import type { EntityType } from 'types/entity';
import {
    EntityDetailRouteConfig,
    EntityTab,
    EntityTabsRoute,
    getEntityNavGuard,
    isEntityDetailRoute,
} from './entity-route-util';
import { SaveResult } from 'types/routes';
import { accessor } from 'src/store';
import { onBeforeRouteLeave, onBeforeRouteUpdate, useRouter } from 'vue-router/composables';
import { useAsyncState, useEventListener } from '@vueuse/core';

/**
 * Checks if route `r2` would be redundant if navigated to from route `r1`
 */
function areRoutesRedundant (r1: Location, r2: Location): boolean {
    if (r1.name !== r2.name) {
        return false;
    }
    if (r1.query !== r2.query) {
        return false;
    }
    // params are preserved if they aren't specified, so we have to check for changed params in r2
    const r1Params = r1.params || {};
    const r2Params = r2.params || {};
    for (const paramName in r2Params) {
        if (r1Params[paramName] !== r2Params[paramName]) {
            return false;
        }
    }
    return true;
}

export default defineComponent({
    name: 'RouteEntityDetail',
    components: {
        EntityDetail,
    },
    props: {
        entityDetailRoute: {
            type: Object as PropType<EntityDetailRouteConfig<EntityType> | RouteConfig>, required: true,
        },
        tabsRoute: {
            type: Object as PropType<EntityTabsRoute | null>, required: false, default: null,
        },
    },
    setup (props) {
        const busy = ref(false);
        const router = useRouter();
        const currentRoute = useRoute();


        // this hook is called when the components are reused.
        // we might need to refetch if the identifying props for the entity have changed (route params), or if entering fork/copy mode.
        onBeforeRouteUpdate((to, from, next) => {
            if (isEntityDetailRoute(props.entityDetailRoute)) {
                // we want to ignore child route changes. to detect this, we find the entity detail route in `from.matched`, and compare it to the one in `to.matched`.
                // if they are the same, then we know that the route change is a child route change, and we can ignore it.
                const fromEntityDetailRouteIdx = from.matched.findIndex(route => route.name === props.entityDetailRoute.name);
                const toEntityDetailRouteIdx = to.matched.findIndex(route => route.name === props.entityDetailRoute.name);
                if (fromEntityDetailRouteIdx === toEntityDetailRouteIdx && fromEntityDetailRouteIdx !== -1) {
                    return next();
                }

                const modeChanged = to.query.mode !== from.query.mode;
                const enteredForkOrCopy = modeChanged && [ EntityDetailMode.Copy, EntityDetailMode.Fork ].includes(to.query.mode as EntityDetailMode);
                const routeParamsForFetch = props.entityDetailRoute.meta.routeParamsForFetch || (route => route.params);
                if (enteredForkOrCopy || !_isEqual(routeParamsForFetch(to), routeParamsForFetch(from))) {
                    return getEntityNavGuard(props.entityDetailRoute, to, from, next);
                }
            }
            next();
        });

        const tabs = computed<EntityTab[]>(() => {
            const result: EntityTab[] = [];
            for (const childRoute of (props.tabsRoute || props.entityDetailRoute).children || []) {
                if (childRoute.name && childRoute.meta?.tab?.label) {
                    if (!childRoute.meta.navigationGuard || childRoute.meta.navigationGuard(childRoute)) {
                        result.push({
                            attrs: {
                                to: { name: childRoute.name, query: currentRoute.value.query },
                                replace: true,
                            },
                            icon: childRoute.meta.tab.icon,
                            label: childRoute.meta.tab.label,
                        });
                    }
                }
            }
            return result;
        });

        const activeTabIdx = ref(0);
        watch(currentRoute, currentRouteVal => {
            for (const matchedRoute of currentRouteVal.matched) {
                const tabIdx = tabs.value.findIndex(tab => tab.attrs?.to?.name === matchedRoute.name && matchedRoute.name);
                if (tabIdx !== -1) {
                    if (activeTabIdx.value !== tabIdx) {
                        activeTabIdx.value = tabIdx;
                    }
                    break;
                }
            }
        }, { immediate: true });
        // when tabs are clicked, navigation will automatically be performed. activeTabIdx will stay in sync too

        const {
            mode,
            editing: entityEditing,
            forking,
            disabled,
        } = useEntityMode();

        useEditing.useProvide(entityEditing);
        useDisabled.useProvide(disabled);

        /**
         * whether the user should be warned if there are unsaved changes before navigating away.
         * @default true
         */
        const warnUnsavedAllowed = computed(() => props.tabsRoute?.meta?.warnUnsaved ?? props.entityDetailRoute.meta?.warnUnsaved ?? true);

        /**
         * whether the lifecycle of the entity is managed by routes, or by child components.
         */
        const isRouteManagedEntity = computed(() => Boolean(isEntityDetailRoute(props.entityDetailRoute) && (props.entityDetailRoute.meta.saveEntity || props.entityDetailRoute.meta.createEntity)));

        const hasChanges = computed(() => {
            if (!entityEditing.value || !isRouteManagedEntity.value) {
                return false;
            }

            const hasChangesFn: (route: Route) => boolean = props.entityDetailRoute.meta?.hasChanges || (() => !_isEqual(accessor.entity?.entity, accessor.originalEntity?.entity));
            return hasChangesFn(currentRoute.value);
        });

        const returnRoute = computed(() => props.tabsRoute?.meta?.returnRoute || props.entityDetailRoute.meta?.returnRoute);

        const unsavedMsg = 'WARNING: unsaved edits will be lost, are you sure you want to continue?';
        /**
         * should be called before attempting to leave the entity with unsaved changes, honoring `warnUnsavedAllowed`.
         *
         * returns true if the user decided to navigate away from the page, otherwise false.
         * will default to true if warnUnsaved is falsey
         */
        function guardUnsaved () {
            if (warnUnsavedAllowed.value && hasChanges.value) {
                // eslint-disable-next-line no-alert
                return confirm(unsavedMsg);
            }
            return true;
        }

        // add/remove a listener to the window object to check if the user is navigating away from the page.
        // this covers actions like hard refresh, or closing the tab.
        useEventListener(window, 'beforeunload', (event: BeforeUnloadEvent) => {
            if (entityEditing.value && !guardUnsaved()) {
                event.preventDefault();
                event.returnValue = unsavedMsg;
            }
        });

        function goBack () {
            if ([ 'function', 'object' ].includes(typeof returnRoute.value)) {
                const returnRouteTarget = typeof returnRoute.value === 'function' ? returnRoute.value(router.currentRoute) : returnRoute.value;
                router.push(returnRouteTarget);
            } else if (mode.value === 'create') {
                window.history.back();
            }
        }

        onBeforeRouteLeave((to, from, next) => {
            if (guardUnsaved()) {
                next();
            } else {
                // cancelled by user
                next(new Error('Navigation cancelled by user'));
            }
        });

        const { state: canFork } = useAsyncState(async () => {
                const routeCanFork = props.entityDetailRoute.meta?.canFork as EntityDetailRouteConfig<EntityType>['meta']['canFork'];
                if (typeof routeCanFork === 'function') {
                    const res = await routeCanFork(router);
                    return res;
                }
                return Boolean(routeCanFork);
            }, false);

        const editing = computed<boolean>({
                get: () => entityEditing.value,
                set: v => {
                    if (currentRoute.value.name) {
                        if (v) {
                            router.replace({
                                name: currentRoute.value.name,
                                params: currentRoute.value.params,
                                query: { ...currentRoute.value.query, mode: 'edit' },
                            });
                        } else { // handle "cancel" event
                            // eslint-disable-next-line no-lonely-if
                            if (isEntityDetailRoute(props.entityDetailRoute)) {
                                if ([
                                    EntityDetailMode.Edit,
                                ].includes(mode.value) && accessor.originalEntity) {
                                    // only check for unsaved changes if the entity is not new. all other cases will be handled by the onBeforeRouteLeave guard.
                                    // eslint-disable-next-line max-depth
                                    if (!guardUnsaved()) {
                                        // cancelled by user
                                        return;
                                    }

                                    accessor.initializeMaintenance({ type: accessor.originalEntity.type, entity: accessor.originalEntity.entity });
                                    router.replace({
                                        name: currentRoute.value.name,
                                        params: currentRoute.value.params,
                                        query: { ...currentRoute.value.query, mode: EntityDetailMode.View },
                                    });
                                } else {
                                    // create, fork, copy, etc
                                    goBack();
                                }
                            } else {
                                router.go(0);
                            }
                        }
                    }
                },
            });

        const modalComponent = ref<(() => Promise<Component>) | null>(null);
        const modalProps = ref<Record<string, unknown>>({});
        const saveModalOpen = ref(false);
        const saveEntity = async () => {
            saveModalOpen.value = false;
            if (!isEntityDetailRoute(props.entityDetailRoute)) {
                return;
            }
            try {
                busy.value = true;

                let saveResult: SaveResult = {
                    status: 'success', // default save result is to assume success
                };

                // call the appropriate persistence method (create / save) and merge the result

                const entityData = accessor.entityAsType(props.entityDetailRoute.meta.entityType);
                const originalEntityData = accessor.originalEntityAsType(props.entityDetailRoute.meta.entityType);

                if (!entityData) {
                    return;
                }
                // call the appropriate save function depending on the mode, updating the saveResult
                if (mode.value === 'create' || mode.value === 'copy') {
                    if (props.entityDetailRoute.meta.createEntity) {
                        saveResult = {
                            ...saveResult,
                            ...(await props.entityDetailRoute.meta.createEntity({
                                mode: mode.value,
                                entity: entityData,
                            })),
                        };
                    } else if (props.entityDetailRoute.meta.saveEntity) { // default to saveEntity if createEntity is not defined
                        saveResult = {
                            ...saveResult,
                            ...(await props.entityDetailRoute.meta.saveEntity({
                                oldEntity: null,
                                mode: mode.value,
                                entity: entityData,
                            })),
                        };
                    }
                } else if (mode.value === 'fork') {
                    if (props.entityDetailRoute.meta.forkEntity) {
                        saveResult = {
                            ...saveResult,
                            ...(await props.entityDetailRoute.meta.forkEntity({
                                mode: mode.value,
                                entity: entityData,
                                oldEntity: originalEntityData,
                            })),
                        };
                    } else if (props.entityDetailRoute.meta.saveEntity) { // default to saveEntity if forkEntity is not defined
                        saveResult = {
                            ...saveResult,
                            ...(await props.entityDetailRoute.meta.saveEntity({
                                oldEntity: null,
                                mode: mode.value,
                                entity: entityData,
                            })),
                        };
                    }
                } else if (props.entityDetailRoute.meta.saveEntity) {
                    saveResult = {
                        ...saveResult,
                        ...(await props.entityDetailRoute.meta.saveEntity({
                            entity: entityData,
                            oldEntity: originalEntityData,
                            mode: mode.value,
                        })),
                    };
                }

                busy.value = false;

                if (saveResult.status === 'success') { // if successful, reset the store and prepare nav
                    if (!saveResult.route) {
                        const saveResultRoute: Location = {
                            ...currentRoute.value,
                            name: currentRoute.value.name as string,
                        };

                        saveResultRoute.query = {
                            ...saveResultRoute.query || {},
                            mode: 'view',
                        };

                        saveResult.route = saveResultRoute;
                    }

                    accessor.initializeMaintenance({ type: accessor.entity!.type, entity: saveResult.newEntity || accessor.entity!.entity });
                } else if (saveResult.status === 'modal') {
                    modalComponent.value = saveResult.modalComponent;
                    modalProps.value = saveResult.modalProps || {};
                    saveModalOpen.value = true;
                }

                if ('route' in saveResult && saveResult.route) {
                    // stripping out everything not needed for route comparison
                    const currentRouteMinimal: Location = {
                        name: router.currentRoute.name as string,
                        params: router.currentRoute.params,
                        query: router.currentRoute.query,
                    };

                    // avoid redundant nav
                    if (!areRoutesRedundant(currentRouteMinimal, saveResult.route)) {
                        console.log('Save result requested route change', saveResult.route);
                        await router.replace({
                            ...saveResult.route,
                            query: {
                                ...currentRoute.value.query,
                                ...(saveResult.route.query ?? {}),
                            },
                        }).catch(err => console.error('router replace error', err));
                    }
                }
            } catch (err) {
                busy.value = false;
                throw err;
            }
        };
        return {
            tabs,
            saveEntity,
            hasChanges,
            saveModalOpen,
            modalComponent,
            modalProps,
            warnUnsaved: warnUnsavedAllowed,
            activeTabIdx,
            isRouteManagedEntity,
            showBackBtn: computed(() => Boolean(returnRoute.value)),
            goBack,
            editing,
            forking,
            forkEntity () {
                router.push({
                    name: currentRoute.value.name!,
                    params: currentRoute.value.params,
                    query: { ...currentRoute.value.query, mode: 'fork' },
                });
            },
            canEdit: computed(() => {
                const routeCanEdit = props.entityDetailRoute.meta?.canEdit;
                if (typeof routeCanEdit === 'function') {
                    return routeCanEdit();
                }
                return Boolean(routeCanEdit);
            }),
            canFork,
        };
    },
});
