var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.value)?_c('v-dialog',{attrs:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-card',{style:({
            height: '80vh',
        }),attrs:{"elevation":2,"color":'#f7f7f7ff'}},[_c('v-tabs',{staticClass:"elevation-2 edc-tabs",staticStyle:{"flex":"0"},attrs:{"flat":"","slider-color":"secondary","background-color":"primary"},model:{value:(_vm.activeTabIdx),callback:function ($$v) {_vm.activeTabIdx=$$v},expression:"activeTabIdx"}},[_vm._l((_vm.tabs),function(tab,idx){return _c('v-tab',{key:idx,staticClass:"edc-tab"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_vm._v(" "+_vm._s(tab.label)+" ")],1)}),_c('v-spacer'),_c('div',{style:({
                    top: '12%',
                    right: '0',
                    position: 'absolute',
                })},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.showUndoDialog = true}}},[_vm._v(" Undo All ")]),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],2),_c('v-tabs-items',{model:{value:(_vm.activeTabIdx),callback:function ($$v) {_vm.activeTabIdx=$$v},expression:"activeTabIdx"}},_vm._l((_vm.tabs),function(tab,contentIdx){return _c('v-tab-item',{key:contentIdx},[(tab.isDefault)?_c('JSONDiff'):_c('EntityChangesDetailView',{attrs:{"entityChangesDetail":_vm.entityChangesDetail},on:{"close":function($event){return _vm.$emit('input', false)}}})],1)}),1)],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.showUndoDialog),callback:function ($$v) {_vm.showUndoDialog=$$v},expression:"showUndoDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Undo ")]),_c('v-card-text',[_vm._v(" Are you sure you want to undo this change? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showUndoDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.handleUndo}},[_vm._v(" Undo ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }