import { v4 as uuidv4 } from 'uuid';
import { getMacroTargetVarpId } from '@redviking/argonaut-core-ui/src/applet/design/macros/get-target-id';
import type { Latest } from '@redviking/argonaut-util/types/mes/applet-designs/appletDesign.latest.zod';
import { ScreenDesigner } from '@redviking/argonaut-util/types/mes/screen-designer/screen-design';
import cellTypeMap from '../cells/cellTypeMap';
import macroTypeMap from '../macros/macroTypeMap';
import { accessor } from 'src/store';
import { PreviousMacroItemData, PreviousScreenItemData, PreviousVarpItemData } from '../getAppletDesignChanges';
import { ChangeLog } from 'src/components/EntityDetail';

function mapId (originalId: string, idMap: Map<string, string>) {
    let mappedId = idMap.get(originalId);
    if (!mappedId) {
        mappedId = uuidv4();
        idMap.set(originalId, mappedId);
    }
    return mappedId;
}

export const copyAppletDesignConfig = (originalConfig: Latest.AppletDesignVersionConfig): Latest.AppletDesignVersionConfig => {
    const idMap = new Map<string, string>();
    const ret = {
        ...originalConfig,
        macros: originalConfig.macros.map(ogMacro => ({
            ...ogMacro,
            id: mapId(ogMacro.id, idMap),
        })),
        varProviders: originalConfig.varProviders.map(ogVarp => {
            const copiedVarp = { ...ogVarp };
            if (copiedVarp.type === 'macroTarget') {
                copiedVarp.attrs.macroId = mapId(copiedVarp.attrs.macroId, idMap);
                copiedVarp.id = getMacroTargetVarpId(copiedVarp.attrs.macroId);
            } else {
                copiedVarp.id = mapId(ogVarp.id, idMap);
            }
            return copiedVarp;
        }),
        screens: originalConfig.screens.map(ogScreen => {
            const newGridLayoutAspectRatios = { ...ogScreen.gridLayoutAspectRatios };
            const cells = ogScreen.cells.map(ogCell => {
                const newCell = {
                    ...ogCell,
                    id: mapId(ogCell.id, idMap),
                };
                if (newCell.type === 'macroTarget') {
                    newCell.attrs = {
                        ...newCell.attrs,
                        macroId: mapId(newCell.attrs.macroId, idMap),
                    };
                }

                // NOTE: not maping new ids for linked grid cells. Do not see the value in doing so since the ids are not used in the database as we store the config as a json blob
                Object.entries(newGridLayoutAspectRatios).forEach(([ aspectRatio, gridLayout ]) => {
                    const newGridLayout = gridLayout;
                    newGridLayout.linkedGridCells = newGridLayout.linkedGridCells.map(lgc => {
                        if (lgc.argoCellId === ogCell.id) {
                            return {
                                ...lgc,
                                argoCellId: newCell.id,
                            };
                        }
                        return lgc;
                    });
                    newGridLayoutAspectRatios[aspectRatio as ScreenDesigner.AspectRatioType] = newGridLayout;
                });

                return newCell;
            });
            const copiedScreen: Latest.Screen.Config = {
                ...ogScreen,
                cells,
                id: mapId(ogScreen.id, idMap),
                gridLayoutAspectRatios: newGridLayoutAspectRatios,
            };
            return copiedScreen;
        }),
    };
    return ret;
};

export const getScreenPreviousItemData = (originalEntity: Latest.AppletDesignVersionConfig, originalScreen: Latest.Screen.Config): PreviousScreenItemData => {
    const varps: Latest.VarProviders.VarProvider[] = [];
    const macros: Latest.Macros.AppletMacro[] = [];

    for (const macroCell of originalScreen.cells.filter((c): c is Latest.Screen.Cells.MacroTargetCell => c.type === 'macroTarget')) {
        const macro = originalEntity.macros.find(m => m.id === macroCell.attrs.macroId);
        if (macro) {
            macros.push(macro);
        }
        const varp = originalEntity.varProviders.find(vp => vp.type === 'macroTarget' && vp.attrs.macroId === macroCell.attrs.macroId);
        if (varp) {
            varps.push(varp);
        }
    }
    return {
        varps,
        macros,
        screen: originalScreen,
        scripts: originalEntity.scripts.filter(s => originalScreen.cells.find(c => c.type === 'btn' && c.attrs.linkedScriptId === s.id)) || [],
    };
};

export const getCellType = (cell: Latest.Screen.Cells.AppletCell): string => {
    if (cell.type === 'macroTarget') {
        return macroTypeMap[cell.attrs.macroType].text;
    }
    return cellTypeMap[cell.type].text;
};

export const getCellName = (cell: Latest.Screen.Cells.AppletCell): string => {
    const treeText = cellTypeMap[cell.type].treeText;
    if (cell.name) {
        return cell.name;
    } else if (cell.type === 'macroTarget') {
        return accessor.entityAsType('appletDesignVersion')?.config.macros.find(m => m.id === cell.attrs.macroId)?.name || 'Unnamed Macro Cell';
    } else if (treeText) {
        return treeText(cell);
    }
    return cellTypeMap[cell.type].text;
};

export const getScreenUpdateMessages = (payload: {
    newScreen: Latest.Screen.Config;
    originalScreen?: Latest.Screen.Config;
}): ChangeLog.MessageType[] => {
    const ret: ChangeLog.MessageType[] = [];

    for (const newCell of payload.newScreen.cells) {
        const originalCell = payload.originalScreen?.cells.find(c => c.id === newCell.id);
        if (originalCell) {
            // TODO: Add in more detailed messages for cell updates based on cell type
            // NOTE: Not in scope at the moment
        } else {
            ret.push({
                type: 'interpolate',
                text: 'A {cellType} cell named {cellName} was added',
                data: [
                    {
                        type: 'code',
                        name: 'cellName',
                        value: getCellName(newCell),
                    },
                    {
                        type: 'code',
                        name: 'cellType',
                        value: getCellType(newCell),
                    },
                ],
            });
        }
    }

    for (const oldCell of payload.originalScreen?.cells || []) {
        if (!payload.newScreen.cells.find(c => c.id === oldCell.id)) {
            ret.push({
                type: 'interpolate',
                text: 'A {cellType} cell named {cellName} was removed',
                data: [
                    {
                        type: 'code',
                        name: 'cellName',
                        value: getCellName(oldCell),
                    },
                    {
                        type: 'code',
                        name: 'cellType',
                        value: getCellType(oldCell),
                    },
                ],
            });
        }
    }

    return ret;
};

export const getVarpPreviousItemData = (originalEntity: Latest.AppletDesignVersionConfig, originalVarp: Latest.VarProviders.VarProvider): PreviousVarpItemData => ({
    varp: originalVarp,
    sparkplugMetrics: originalEntity.sparkplugDeviceMetrics.filter(m => originalVarp.outputs.includes(m.name)) || [],
    script: originalVarp.type === 'script'
        ? originalEntity.scripts.find(s => originalVarp.attrs.scriptId === s.id)
        : undefined,
});


export const getMacroPreviousItemData = (originalEntity: Latest.AppletDesignVersionConfig, originalMacro: Latest.Macros.AppletMacro): PreviousMacroItemData => {
    const cellFromCfg = accessor.appletDesign.findCellInCfg(c => c.type === 'macroTarget' && c.attrs.macroId === originalMacro.id, true);
    if (!cellFromCfg) {
        throw new Error(`Could not find cell for macro ${originalMacro.id}`);
    }

    const childCells: {
        cellIdx: number;
        cellCfg: Latest.Screen.Cells.AppletCell;
    }[] = accessor.appletDesign.getAllChildCells(cellFromCfg.cellCfg.id, true).map(cell => ({
        cellIdx: cell.cellIdx,
        cellCfg: cell.cellCfg,
    }));

    const varp = originalEntity.varProviders.find(vp => vp.type === 'macroTarget' && vp.attrs.macroId === originalMacro.id);
    if (!varp) {
        throw new Error(`Could not find varp for macro ${originalMacro.id}`);
    }
    return {
        varp,
        macroCfg: originalMacro,
        cellData: {
            screenId: cellFromCfg.screen.id,
            screenIdx: cellFromCfg.screenIdx,
            cellCfgs: [
                {
                    cellCfg: cellFromCfg.cellCfg,
                    cellIdx: cellFromCfg.cellIdx,
                },
                ...childCells,
            ],
        },
    };
};
