/* eslint-disable complexity */
/* eslint-disable max-depth */
import { validateAcls } from '@redviking/argonaut-core-ui/src/auth';
import { CausalError } from '@redviking/causal-error';
import { ProcessDataElementCollectionKindEnum } from 'types/db';
import { Location } from 'vue-router';
import type { DataElement, EntityProcessSpecConfig, ProcessEntity } from './process.entity';
import { SaveResult } from 'types';

export function validateSpecificationSelectAcl (): boolean {
    return validateAcls([ 'spec_select' ]);
}

export function validateSpecificationInsertAcl (): boolean {
    return validateAcls([ 'spec_insert' ]);
}

export function validateSpecificationUpdateAcl (): boolean {
    return validateAcls([ 'spec_update' ]);
}

export function validateSpecificationDeleteAcl (): boolean {
    return validateAcls([ 'spec_delete' ]);
}

export function validateProcessEntity (entity: ProcessEntity, currentRoute: Location): Promise<{ err: CausalError, route: Location } | SaveResult | null> {
    if (!entity.name) {
        return Promise.resolve({
            err: new CausalError('Process name is required'),
            route: {
                name: 'process-general',
                query: currentRoute.query,
            },
        });
    }

    const processParams: DataElement[] = [];

    for (const dataElement of Object.values(entity.dataElements)) {
        if (dataElement.collectionKind === ProcessDataElementCollectionKindEnum.SpecParam) {
            processParams.push(dataElement);
        }
    }

    for (const validation of Object.values(entity.config.validationOpts)) {
        if (!validation.name) {
            return Promise.resolve({
                err: new CausalError('Validation name is required'),
                route: {
                    name: 'process-validations',
                    query: currentRoute.query,
                    params: currentRoute.params,
                },
            });
        }
    }

    const pendingMedia = new Map<string, NonNullable<EntityProcessSpecConfig['media']>[string][]>();

    const specs = [
        ...Object.values(entity.defaultSpecs),
        ...Object.values(entity.nonDefaultSpecs),
    ];
    for (let i = 0; i < specs.length; i++) {
        const spec = specs[i];
        if (!spec.name) {
            return Promise.resolve({
                err: new CausalError('Specification name is required'),
                route: {
                    name: 'process-specs',
                    query: currentRoute.query,
                    params: currentRoute.params,
                },
            });
        }

        Object.values(spec.config.media || {}).forEach(media => {
            if (media.media.type === 'pending') {
                pendingMedia.set(spec.id, [ ...(pendingMedia.get(spec.id) || []), media ]);
            }
        });

        if (pendingMedia.size) {
            return Promise.resolve({
                status: 'modal',
                modalComponent: () => import(/* webpackChunkName: "material" */ './MediaUploadDialog.view.vue').then(m => m.default),
            });
        }

        if (spec.isDefault) {
            // ensure all default specs have parameter values
            // TODO: skip this check for retired specs

            for (const processParam of processParams) {
                if (!spec.config.paramValues[processParam.id]) {
                    return Promise.resolve({
                        err: new CausalError(`Default spec "${spec.name}" is missing a value for parameter "${processParam.name}"`),
                        route: {
                            name: 'process-params',
                            query: currentRoute.query,
                            params: currentRoute.params,
                        },
                    });
                }
            }

        }
    }

    // validate the validations
    // for (const [] of Object.entries(entity.config.validationOpts)) {
    // }

    // for (let calcIdx = 0; calcIdx < processRev.calcs.length; calcIdx++) {
    //     const calc = processRev.calcs[calcIdx];
    //     const pde = processRev.process.outputPdes.find(p => p.id === calc.prde?.pdeId);
    //     if (!pde) {
    //         return {
    //             err: new CausalError('Calculation data element output is required', `Calculation ${calcIdx + 1}`),
    //             route: { ...currentRoute, name: 'process-calcs' },
    //         };
    //         // throw new Error(`Calculation (${calcIdx + 1}) does not have a data element selected for its output`);
    //     }
    //     const futurePdes = processRev.calcs.slice(calcIdx + 1).map(c => c.prde?.pdeId).filter(c => c) as string[];
    //     for (const step of calc.steps) {
    //         for (const input of step.inputs) {
    //             if (step.stepNum === 1) {
    //                 if (input.mode === ArgoProcessCalcInputModeEnum.PreviousStepResult) {
    //                     return {
    //                         err: new CausalError('The first step of a calculation cannot have an input with a mode of "previous"', `Calculation "${pde.dataElement.name}"`),
    //                         route: { ...currentRoute, name: 'process-calcs' },
    //                     };
    //                 }
    //             }
    //             if (!input.pde && input.mode !== ArgoProcessCalcInputModeEnum.PreviousStepResult) {
    //                 return {
    //                     err: new CausalError('Input value is required', `Input (${input.operandPosition}) of step (${step.stepNum}) in calculation (${pde.dataElement.name})`),
    //                     route: { ...currentRoute, name: 'process-calcs' },
    //                 };
    //             }
    //             if (input.mode === ArgoProcessCalcInputModeEnum.SpecParam && !processRev.params.find(param => param.pdeId === input.pde?.id)) {
    //                 return {
    //                     err: new CausalError('Inputs with a mode of "spec_param" must be set to a process param data element', `Input (${input.operandPosition}) of step (${step.stepNum}) in calculation (${pde.dataElement.name})`),
    //                     route: { ...currentRoute, name: 'process-calcs' },
    //                 };
    //             }
    //             if (input.pde && futurePdes.includes(input.pde.id)) {
    //                 return {
    //                     err: new CausalError('Inputs cannot reference the output of a future calculation', `Input (${input.operandPosition}) of step (${step.stepNum}) in calculation (${pde.dataElement.name})`),
    //                     route: { ...currentRoute, name: 'process-calcs' },
    //                 };
    //             }
    //         }
    //     }
    // }
    // if (processRev.specs.length === 0) {
    //     return {
    //         err: new CausalError('At least one specification is required per process'),
    //         route: {
    //             ...currentRoute,
    //             name: 'process-spec',
    //         },
    //     };
    // }

    // for (const spec of processRev.specs) {

    //     for (const val of spec.validations) {
    //         for (let i = 0; i < val.operator.input_count; i++) {
    //             const input = val.inputs[i];
    //             if (
    //                 (input.type === ArgoSpecValidationInputTypeEnum.Pde && !input.pde) ||
    //                 (input.type === ArgoSpecValidationInputTypeEnum.Number && !input.num_val) ||
    //                 (input.type === ArgoSpecValidationInputTypeEnum.String && !input.str_val)
    //             ) {
    //                 return {
    //                     err: new CausalError('Input value is required', `Input (${input.operandPosition}) of validation (${val.name}) in spec (${spec.name})`),
    //                     route: {
    //                         ...currentRoute,
    //                         name: 'process-spec',
    //                         params: {
    //                             ...currentRoute.params,
    //                             specId: spec.id,
    //                         },
    //                     },
    //                 };
    //             }
    //             if (input.mode === ArgoSpecValidationInputModeEnum.SpecParam && !processRev.params.find(param => param.pdeId === input.pde?.id)) {
    //                 return {
    //                     err: new CausalError('Inputs with a mode of "spec_param" must be set to a process param data element', `Input (${input.operandPosition}) of validation (${val.name}) in spec (${spec.name})`),
    //                     route: {
    //                         ...currentRoute,
    //                         name: 'process-spec',
    //                         params: {
    //                             ...currentRoute.params,
    //                             specId: spec.id,
    //                         },
    //                     },
    //                 };
    //             }
    //         }
    //     }
    // }

    // const prdes = getPrdes(processRev);
    // for (const outputPde of processRev.process.outputPdes) {
    //     if (!prdes.find(prde => prde.pdeId === outputPde.id)) {
    //         return {
    //             err: new CausalError('Process revision needs to provide a way to get a value for all outputs', `Unconfigured output "${outputPde.dataElement.name}"`),
    //             route: currentRoute,
    //         };
    //     }
    // }

    return Promise.resolve(null);
}
