import * as monaco from 'monaco-editor/esm/vs/editor/editor.api.js';
import { accessor } from 'src/store';
import { Notify } from 'src/notifications';
import { Ref, computed, onBeforeUnmount, watch } from 'vue';
import { onMounted } from 'vue';
import appletScriptLibMd from 'raw-loader!@redviking/argonaut-util/src/argo-lang/applet-script-lib.md';
import {
    appletLibPath,
    appletVarsPath,
    argoScriptPath,
    generateAppletVarLib,
    tsCompilerOptions,
} from '@redviking/argonaut-util/src/argo-lang/parser';

// If applet-script-lib is a .d.ts, then the ts-loader includes it in type checking, which breaks a lot of things.
// To avoid this I wrapped it in a markdown file so we still have syntax highlighting
const appletScriptLib = appletScriptLibMd.replace(/```.*/ug, '');

// as far as I can tell, these can only be global.
// parts of this app expect these specific settings.
// if we need to ever change them elsewhere, we'll need to work this into `useMonacoEditor` and `validateScript`.
monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
    noSemanticValidation: false,
    noSyntaxValidation: false,
});
monaco.languages.typescript.javascriptDefaults.setCompilerOptions(tsCompilerOptions as monaco.languages.typescript.CompilerOptions);

/**
 * all scripts must share the same model,
 * because monaco's typescript services considers all models
 * part of the same "project", and there's risk for conflicts
 * https://github.com/microsoft/monaco-editor/issues/2976
 */
export const argoScriptUri = monaco.Uri.parse(argoScriptPath);
export const argoScriptModel = monaco.editor.getModel(argoScriptUri) || monaco.editor.createModel('', 'javascript', argoScriptUri);
export const appletLibUri = monaco.Uri.parse(appletLibPath);
export const appletVarsUri = monaco.Uri.parse(appletVarsPath);

function setExtraLibs (availableVars: string[]) {
    const extraLibs: { content: string, filePath: string }[] = [];
    if (appletScriptLib) {
        if (availableVars.length > 0) {
            extraLibs.push({
                content: generateAppletVarLib(availableVars),
                filePath: appletVarsUri.toString(),
            });
        }

        extraLibs.push({ content: appletScriptLib, filePath: appletLibUri.toString() });
    }
    monaco.languages.typescript.javascriptDefaults.setExtraLibs(extraLibs);
}

/**
 * there can only be one monaco editor instance at a time.
 * https://github.com/microsoft/monaco-editor/issues/2976
 */
let instanceCounter = 0;

export function useMonacoEditor (opts: {
    editorElementRef: Ref<HTMLElement | null>;
    scriptRef: Ref<string>;
    readOnlyRef: Ref<boolean>;
    strictReadonly?: boolean;
    varpId?: string;
}) {
    onMounted(() => {
        if (!opts.strictReadonly) {
            if (instanceCounter > 0) {
                Notify.error(new Error('Only one monaco editor instance can be allowed at a time'));
            }
            instanceCounter++;
        }
    });

    let editor: monaco.editor.IStandaloneCodeEditor | null = null;
    watch(opts.editorElementRef, editorElement => {
        if (editor) {
            editor.dispose();
            editor = null;
        }

        if (!editorElement) {
            return;
        }

        editor = monaco.editor.create(editorElement, {
            model: argoScriptModel,
            readOnly: opts.readOnlyRef.value || opts.strictReadonly,
            quickSuggestions: false,
            automaticLayout: true,
            fixedOverflowWidgets: true,
            suggest: {
                showFunctions: true,
                showConstructors: false,
                showDeprecated: false,
                showFields: true,
                showVariables: false,
                showClasses: false,
                showStructs: false,
                // showInterfaces: false,
                showModules: false,
                // showProperties: false,
                showEvents: false,
                showOperators: false,
                showUnits: false,
                showValues: false,
                showConstants: false,
                showEnums: false,
                showEnumMembers: false,
                showKeywords: false,
                showWords: false,
                showColors: false,
                showFiles: false,
                showReferences: false,
                showFolders: false,
                showTypeParameters: false,
                showIssues: false,
                showUsers: false,
                // showSnippets: false,
            },
            showDeprecated: false,
            minimap: {
                enabled: false,
            },
        });

        editor.onDidBlurEditorText(() => {
            opts.scriptRef.value = editor!.getValue() || '';
        });
    }, { immediate: true });

    watch(opts.scriptRef, script => {
        argoScriptModel.setValue(script);
    }, { immediate: true });

    watch(opts.readOnlyRef, readOnly => {
        editor?.updateOptions({ readOnly });
    }, { immediate: true });

    const availableVars = computed(() => accessor.appletDesign.availableVars({ forProvider: opts.varpId, includeForProvider: true }));
    watch([ availableVars ], () => {
        setExtraLibs(availableVars.value);
    }, { immediate: true });

    onBeforeUnmount(() => {
        if (!opts.strictReadonly) {
            instanceCounter--;
        }
        if (editor) {
            editor.dispose();
            editor = null;
        }
    });
}

export async function validateScript (script: string, availableVars: string[]) {
    // preserve the original script and libs
    const originalScript = argoScriptModel.getValue();
    const originalExtraLibsRecord = monaco.languages.typescript.javascriptDefaults.getExtraLibs();
    const originalExtraLibs = Object.entries(originalExtraLibsRecord).map(([ filePath, extraLib ]) => ({
        content: extraLib.content,
        filePath,
    }));

    // set the script to validate
    argoScriptModel.setValue(script);
    setExtraLibs(availableVars);

    const lspWorker = await monaco.languages.typescript.getJavaScriptWorker().then(workerFn => workerFn(argoScriptUri));

    // prompt the worker to validate the script, we'll use model markers though because they're more accurate immediately.
    await lspWorker.getSyntacticDiagnostics(argoScriptUri.toString());
    await lspWorker.getSemanticDiagnostics(argoScriptUri.toString());
    const modelMarkers = monaco.editor.getModelMarkers({ resource: argoScriptUri }).filter(mm => mm.severity === monaco.MarkerSeverity.Error);

    // restore the original script and libs
    argoScriptModel.setValue(originalScript);
    monaco.languages.typescript.javascriptDefaults.setExtraLibs(originalExtraLibs);

    return { modelMarkers };
}
