export const messages = {
    desc: 'Open a UDP socket to send and/ or receive data',
    encoding: {
        items: {
            hex: 'Hex',
            utf8: 'UTF-8',
        },
        label: 'How to interpret the received payload',
    },
    errors: {
        _other: 'Config is invalid',
        clientTimeout: 'Client timeout is invalid',
        host: 'Host is invalid',
        payloadVar: 'Payload variable is required',
        port: 'Port is invalid',
    },
    hostInterface: {
        bind: 'Bind port',
        clientTimeout: 'Client timeout (s)',
        label: 'The network interface / IP to listen on',
    },
    port: 'Port',
    receivedPayloadVar: 'Variable to store the received payload in',
    receivedTsVar: 'Store the timestamp of the last received payload in a variable (optional)',
    sendBehavior: {
        condition: {
            prefix: 'Only send the payload when',
            suffix: 'the following comparisons are satisfied:',
        },
        title: 'Send Behavior',
    },
    sendPayloadVar: 'Content to send',
    sendTsVar: 'Store the timestamp of the last sent payload in a variable (optional)',
    title: 'UDP Socket',
};
