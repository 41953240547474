import { z } from 'zod';
import { appletDesignVersionConfigSchema_v1 } from './v1/applet-design.v1.zod';
import { appletDesignVersionConfigSchema_v2 } from './v2/appletDesign.v2.zod';
import { appletDesignVersionConfigSchema_v3 } from './v3/appletDesign.v3.zod';

export const appletDesignSchema = z.discriminatedUnion('schemaVersion', [
    appletDesignVersionConfigSchema_v1,
    appletDesignVersionConfigSchema_v2,
    appletDesignVersionConfigSchema_v3,
]);

export type AppletDesignConfig = z.infer<typeof appletDesignSchema>;
